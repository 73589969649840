import AlimtalkSendModalService from '@service/AlimtalkSendModalService';
import AlimtalkSendModalModel from '@model/AlimtalkSendModalModel';
import portalModel from '@model/portalModel';
import { runInAction, observable, toJS } from "mobx";
import { _confirm } from "@model/dialogModel";

class AlimtalkSendModalVm {
    load(businessId) {
        AlimtalkSendModalService.load(businessId);
    }
    get alimtalkSendData() {
        return AlimtalkSendModalModel.alimtalkSendData;
    }
    selectedIds(e, id) {
        if(this.alimtalkSendData.selectedIds.includes(id)) {
            this.alimtalkSendData.selectedIds = this.alimtalkSendData.selectedIds.filter(_id => _id !== id);
        }else{
            this.alimtalkSendData.selectedIds.push(id);
        }
    }
    changeContent(e) {
        AlimtalkSendModalModel.alimtalkSendData.content = e.target.value;
    }
    canSendTalk(businessId) {
        let active = true;
        if(businessId) {
            if(this.alimtalkSendData.selectedIds.length === 0) {
                active = false;
            }
        }
        if(!this.alimtalkSendData.content) {
            active = false;
        }
        return active;
    }
    async send(businessId, businessIds) {
        if(await _confirm('발송하시겠습니까?')) {
            await AlimtalkSendModalService.send(businessId, businessIds);
            runInAction(() => {
                portalModel.title = "";
                portalModel.content = null;
            })
        }
    }
}
export default new AlimtalkSendModalVm();