import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import BusinessListModel from '@model/BusinessListModel';

class BusinessListService {
    async load() {
        const res = await get(`/business/grid/column`);
        BusinessListModel.setColumns(res.data.columns);
    }
    async gridData(params, selectedUserId) {
        params.staffId = selectedUserId;
        const res = await post('/business/grid', params);
        return res.data;
    }
    async onDragStopped(params) {
        await put('/business/grid/column/state', params);
    }
    async onChangeData(params, selectedUserId) {
        params.staffId = selectedUserId;
        await put('/business/grid/column', params);
    }
    async loadGridFilter() {
        const res = await get(`/auth/team/employees`);
        BusinessListModel.setEmployeeList(res.data.employeeList);
        BusinessListModel.setSelectedUserId(res.data.selectedUserId);
    }
}
export default new BusinessListService();