import ValueAddedTaxModel from '@model/ValueAddedTaxModel';
import ValueAddedTaxService from '@service/ValueAddedTaxService';
import {toJS} from 'mobx';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';

class PreNoticeVm {
    get vatDetailDto() {
        return ValueAddedTaxModel.process.vatDetailDto;
    }
    dueDateForTaxAmountOfNotice(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }

        ValueAddedTaxModel.process.vatDetailDto.vat.dueDateForTaxAmountOfNotice = date;
    }
    taxAmountOfNotice(e) {
        ValueAddedTaxModel.process.vatDetailDto.vat.taxAmountOfNotice = onlyNumber(removeCommas(e.target.value));
    }
    alimTalkToList(e, index) {
        ValueAddedTaxModel.process.vatDetailDto.vat.alimTalkToList[index].checkedYn = e.target.checked;
    }
    get activeVat() {
        let active = true;
        if(!this.vatDetailDto.vat.taxAmountOfNotice && this.vatDetailDto.vat.taxAmountOfNotice !== 0) {
            active = false;
        }
        if(!this.vatDetailDto.vat.dueDateForTaxAmountOfNotice) {
            active = false;
        }
        const checkedYn = this.vatDetailDto.vat.alimTalkToList.some((item) => { //알림톡 받으실분
            return item.checkedYn === true
        })
        if(checkedYn === false) {
            active = false;
        }
        return active;
    }

    get vatParam() {
        let param = {
            vatDetailDto : {
                returnCompletedType : "예정신고X_예정고지O",
                taxAmountOfNotice : this.vatDetailDto.vat.taxAmountOfNotice,
                dueDateForTaxAmountOfNotice : {
                    year : this.vatDetailDto.vat.dueDateForTaxAmountOfNotice.split('-')[0],
                    month : this.vatDetailDto.vat.dueDateForTaxAmountOfNotice.split('-')[1],
                    day : this.vatDetailDto.vat.dueDateForTaxAmountOfNotice.split('-')[2]
                },
                alimTalkToIds : this.vatDetailDto.vat.alimTalkToList.filter((person) => {
                    return person.checkedYn === true
                }).map((checkedPerson) => {
                    return checkedPerson.id
                })
            }
        }
        return param;
    }
    sendPreliminaryNoticeAmount(businessId) {
        let param = Object.assign({}, this.vatParam);
        param.vatDetailDto.type = param.vatDetailDto.returnCompletedType;
        delete param.vatDetailDto.returnCompletedType;
        ValueAddedTaxService.sendPreliminaryNoticeAmount(param, businessId, ValueAddedTaxModel.process.vatId);
    }
    saveVat(businessId) {
        const param = Object.assign({}, this.vatParam);
        param.vatDetailDto.updateType = param.vatDetailDto.returnCompletedType;
        delete param.vatDetailDto.returnCompletedType;

        ValueAddedTaxService.saveVat(param, businessId, ValueAddedTaxModel.process.vatId);
    }
}
export default new PreNoticeVm();