function email() { //이메일 validation
    return /^(.+)@(.+)$/;
}

function onlyKorean() { //한글
    return /^[가-힣 ]{0,}$/;
}

function onlyKorEng() { //한글, 영문 입력
    return /^[가-힣a-zA-Z ]{0,}$/;
}

function onlyKorEng_nospacing() {
    return /^[가-힣a-zA-Z]+$/;
}

function phoneNumber() { //핸드폰 번호
    return /^[0]{0,1}[1][0][-]{0,1}[0-9]{3,4}[-]{0,1}[0-9]{4}$/;
}

function deleteSpace(x) {
    return x.replace(/\s/g, "");
}

export default { email, onlyKorean, onlyKorEng, onlyKorEng_nospacing, phoneNumber, deleteSpace };