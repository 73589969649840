import { observable } from 'mobx';

const returnArray = (_array) => {
    if(!_array) {
        return [];
    }else{
        return _array
    }
};

const CorporateTaxModel = observable({
    process : null,
    taxInfo : null,
    setProcess(data) {
        this.process = {
            corporateTaxId : data?.corporateTaxId || "",
            businessName : data?.businessName || "",
            status : data?.status || "",
            alimTalkToList : {
                dataSubmission : returnArray(data?.alimTalkToList?.dataSubmission).map((item) => {
                    return {
                        id : item?.id || "",
                        name : item?.name || "",
                        position : item?.position || "",
                        checkedYn : item?.checkedYn || false
                    }
                }),
                requestingDocument : returnArray(data?.alimTalkToList?.requestingDocument).map((item) => {
                    return {
                        id : item?.id || "",
                        name : item?.name || "",
                        position : item?.position || "",
                        checkedYn : item?.checkedYn || false
                    }
                }),
                settlementWork : returnArray(data?.alimTalkToList?.settlementWork).map((item) => {
                    return {
                        id : item?.id || "",
                        name : item?.name || "",
                        position : item?.position || "",
                        checkedYn : item?.checkedYn || false
                    }
                })
            },
            taxBillSendWayType : data?.taxBillSendWayType || "",
            instalmentYn : data?.instalmentYn ?? null
        }
    },
    setTaxInfo(taxInfo) {
        this.taxInfo = {
            nationalTax_1st : {
                downloadUrl : taxInfo?.nationalTax_1st?.downloadUrl || "",
                files: 
                    taxInfo?.nationalTax_1st?.files ? 
                        taxInfo?.nationalTax_1st?.files.map((file) => {
                            return {
                                fileName : file?.fileName || ""
                            }
                        }) : []
            },
            nationalTax_2nd : {
                downloadUrl : taxInfo?.nationalTax_2nd?.downloadUrl || "",
                files: 
                    taxInfo?.nationalTax_2nd?.files ? 
                        taxInfo?.nationalTax_2nd?.files.map((file) => {
                            return {
                                fileName : file?.fileName || ""
                            }
                        }) : []
            },
            localTaxFile : {
                downloadUrl : taxInfo?.localTaxFile?.downloadUrl || "",
                files: 
                    taxInfo?.localTaxFile?.files ? 
                        taxInfo?.localTaxFile?.files.map((file) => {
                            return {
                                fileName : file?.fileName || ""
                            }
                        }) : []
            },
            nationalTaxFile : {
                downloadUrl : taxInfo?.nationalTaxFile?.downloadUrl || "",
                files: 
                    taxInfo?.nationalTaxFile?.files ? 
                        taxInfo?.nationalTaxFile?.files.map((file) => {
                            return {
                                fileName : file?.fileName || ""
                            }
                        }) : []
            },
            statementOfTaxAdjustment : {
                downloadUrl : taxInfo?.statementOfTaxAdjustment?.downloadUrl || "",
                files: 
                    taxInfo?.statementOfTaxAdjustment?.files ? 
                        taxInfo?.statementOfTaxAdjustment?.files.map((file) => {
                            return {
                                fileName : file?.fileName || ""
                            }
                        }) : []
            },
            etcFile : {
                downloadUrl : taxInfo?.etcFile?.downloadUrl || "",
                files: 
                    taxInfo?.etcFile?.files ? 
                        taxInfo?.etcFile?.files.map((file) => {
                            return {
                                fileName : file?.fileName || ""
                            }
                        }) : []
            },
            financialStatementsConfirmation :  taxInfo?.financialStatementsConfirmation ? {
                id : taxInfo?.financialStatementsConfirmation?.id || "",
                name : taxInfo?.financialStatementsConfirmation?.fileName || "",
                downloadUrl : taxInfo?.financialStatementsConfirmation?.downloadUrl || ""
            } : null,
            alimTalkToList : returnArray(taxInfo?.alimTalkToList).map((item) => {
                return {
                    id : item?.id || "",
                    name : item?.name || "",
                    position : item?.position || "",
                    checkedYn : item?.checkedYn || false
                }
            }),
            nationalTax : {
                finalTaxLiability : taxInfo?.nationalTax?.finalTaxLiability ?? "",
                prePaidTax : taxInfo?.nationalTax?.prePaidTax || "",
                additionalTaxPayable : taxInfo?.nationalTax?.additionalTaxPayable || "",
                specialTaxForRuralDevelopment : taxInfo?.nationalTax?.specialTaxForRuralDevelopment || "",
                taxPayable_1st : taxInfo?.nationalTax?.taxPayable_1st || "",
                dueDateForPayment_1st : taxInfo?.nationalTax?.dueDateForPayment_1st || "",
                taxPayable_2nd : taxInfo?.nationalTax?.taxPayable_2nd || "",
                dueDateForPayment_2nd : taxInfo?.nationalTax?.dueDateForPayment_2nd || "",
                dueDateForPayment : taxInfo?.nationalTax?.dueDateForPayment || "",
                alimTalkToList : returnArray(taxInfo?.nationalTax?.alimTalkToList).map((item) => {
                    return {
                        id : item?.id || "",
                        name : item?.name || "",
                        position : item?.position || "",
                        checkedYn : item?.checkedYn || false
                    }
                }),
            },
            localTax : {
                taxAmount : taxInfo?.localTax?.taxAmount ?? "",
                dueDateForPayment : taxInfo?.localTax?.dueDateForPayment || "",
                alimTalkToList : returnArray(taxInfo?.localTax?.alimTalkToList).map((item) => {
                    return {
                        id : item?.id || "",
                        name : item?.name || "",
                        position : item?.position || "",
                        checkedYn : item?.checkedYn || false
                    }
                }),
            }
        }
    }
});

export default CorporateTaxModel;