import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import HomeVm from './HomeVm';
import PageNav from '@commonComponents/PageNav/PageNav';
function Home() {
    return useObserver(() => (
        <>
            <PageNav nav={["나의 할일", "홈"]}/>
        </>
    ));
}
export default Home;