import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {pageRouter} from '@model/pageModel';

import { Router, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
  dsn: 'https://435b09bd882b254c605cfe5188443f5d@o774769.ingest.sentry.io/4506301955571712',
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: [
        /^https:\/\/accounting\.admin\.api\.standby\.kr/
      ],
      networkRequestHeaders: [
        'Authorization',
        'Accept',
        'User-Agent',
        'Cache-Control',
      ],
      networkResponseHeaders: [
        'Content-Type',
        'Date',
      ],
    }),
  ],
  environment : process.env.NODE_ENV,
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maskAllText: false,
  maskAllInputs: false,
  blockAllMedia: false,
  ignoreErrors: [
    'AxiosError',
  ],
});

root.render(
    <HistoryRouter history={pageRouter.history}>
      <App />
    </HistoryRouter>
);
