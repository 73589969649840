import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import { pageRouter } from '@model/pageModel';
import LoginService from '@service/LoginService';
import { _alert, _confirm } from "@model/dialogModel";

class JoinService {
    async loadTerms() {
        const res = await get('/term');
        return res.data;
    }
    async join(param) {
        const res = await post('/user/register', param);
        if(res.data.loginPermissionYn === false) {
            await _alert('가입신청이 완료되었습니다. 관리자가 승인 후 사용이 가능합니다.');
            window.location.reload();
        }else{
            await LoginService.loginSuccess(res.data);
        }
    }
}
export default new JoinService();