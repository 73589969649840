import BusinessHomeService from '@service/BusinessHomeService';
import BusinessHomeModel from '@model/BusinessHomeModel';
import { runInAction, observable, toJS } from "mobx";
import portalModel from '@model/portalModel';
import BusinessRegistrationModal from './BusinessRegistrationModal/BusinessRegistrationModal';
import StockholderRegisterRequestModal from './StockholderRegisterRequestModal/StockholderRegisterRequestModal';
import NewBookkeepingContractModal from './NewBookkeepingContractModal/NewBookkeepingContractModal';
import NewCmsAccountContractModal from './NewCmsAccountContractModal/NewCmsAccountContractModal';
import AvoidBookkeepingContractModal from './AvoidBookkeepingContractModal/AvoidBookkeepingContractModal';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';
import { _alert, _confirm } from "@model/dialogModel";

class BusinessHomeVm {
    constructor() {
        this.state = observable({
            customerMoreViewOpened : false
        })
    }    
    load(businessId) {
        BusinessHomeService.load(businessId);
    }
    get business() {
        return BusinessHomeModel.business;
    }
    valueAddedTaxSelectedIndex(e, businessId) {
        this.business.progress.valueAddedTax.selectList.selectedIndex = e.target.value;

        const selectedPeriod = this.business.progress.valueAddedTax.selectList.periodList[e.target.value];
        BusinessHomeService.valueAddedTaxProgressSelect(businessId, selectedPeriod.year, selectedPeriod.value);
    }
    corporateTaxTaxSelectedIndex(e, businessId) {
        this.business.progress.corporateTax.selectList.selectedIndex = e.target.value;

        const selectedPeriod = this.business.progress.corporateTax.selectList.periodList[e.target.value];
        BusinessHomeService.corporateTaxProgressSelect(businessId, selectedPeriod.year);
    }
    businessRegistrationModalOpen() {
        runInAction(() => {
            portalModel.title = "사업자등록증 변경";
            portalModel.content = <BusinessRegistrationModal/>;
        })
    }
    stockholderRegisterRequestModalOpen() {
        runInAction(() => {
            portalModel.title = "최신 주주명부 요청";
            portalModel.content = <StockholderRegisterRequestModal/>;
        })
    }
    newBookkeepingContractModalOpen() {
        runInAction(() => {
            portalModel.title = "기장계약서 새로 작성하기";
            portalModel.content = <NewBookkeepingContractModal/>;
        })
    }
    newCmsAccountContractModalOpen() {
        runInAction(() => {
            portalModel.title = "CMS계약서 새로 작성하기";
            portalModel.content = <NewCmsAccountContractModal/>;
        })
    }
    avoidBookkeepingContractModalOpen() {
        runInAction(() => {
            portalModel.title = "기장계약 해지하기";
            portalModel.content = <AvoidBookkeepingContractModal/>;
        })
    }
    cancelBookkeepingContract(businessId) {
        BusinessHomeService.cancelBookkeepingContract(businessId);
    }
    async fileDown(url) {
        if(!url) {
            return await _alert('등기부가 존재하지 않습니다.');
        }
        await BusinessHomeService.fileDownload(url);
    }
    openAlimtalkModal(businessId) {
        runInAction(() => {
            portalModel.title = "수동 알림톡 발송";
            portalModel.content = <AlimtalkSendModal businessId={businessId}/>;
        })
    }
}
export default new BusinessHomeVm();