import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from './BusinessNameTimeline.module.scss';
import vm from './BusinessNameTimelineVm';

function BusinessNameTimeline(props) {
    return useObserver(() => (
        <span className={st.BusinessNameTimeline}>
            {props.value}
            <button type="button" className={st.historyBtn} onClick={() => vm.openWindow(props.data.businessId)}/>
        </span>
    ));
}
export default BusinessNameTimeline;