import { post, get, put, downloadFileGet } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import BusinessHomeModel from '@model/BusinessHomeModel';
import { _alert, _confirm } from "@model/dialogModel";
import formDataToJson, { objectToFormData } from '@common/module/submit';

class BusinessHomeService {
    async load(businessId) {
        const res = await get(`/business/${businessId}/home`);
        BusinessHomeModel.setBusiness(res.data);
    }
    async valueAddedTaxProgressSelect(businessId, year, period) {
        const res = await get(`/business/${businessId}/home/valueAddedTax?year=${year}&period=${period}`);

        BusinessHomeModel.setValueAddedTaxProgress(res.data);
    }
    async corporateTaxProgressSelect(businessId, year) {
        const res = await get(`/business/${businessId}/home/corporateTax?year=${year}`);

        BusinessHomeModel.setCorporateTaxProgress(res.data);
    }
    async editHome(param, businessId) {
        await put(`/business/${businessId}/home`, param);
        await this.load(businessId);
        await _alert('수정되었습니다.');
    }
    async saveBusinessRegistration(businessId, param) {
        const formData = new FormData();
        objectToFormData(formData, param);
        await post(`/business/${businessId}/home/businessRegistration`, formData);
        await _alert('저장되었습니다.');
        window.location.reload();
    }
    async alimtalkBusinessRegistration(businessId, param) {
        const formData = new FormData();
        objectToFormData(formData, param);
        await post(`/business/${businessId}/home/businessRegistration/alimtalk`, formData);
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async alimtalkStockholderList(businessId, param) {
        await post(`/business/${businessId}/home/shareholderList`, param);
        await _alert('주주명부 요청이 접수되었습니다.', '1~3분 소요됩니다. 잠시만 기다려주세요.');
        window.location.reload();
    }
    async alimtalkBookkeepingContract(businessId, param) {
        await post(`/business/${businessId}/home/bookkeepingContract/alimtalk`, param);
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async alimtalkCmsAccountContract(businessId, param) {
        await post(`/business/${businessId}/home/cmsAccountContract/alimtalk`, param);
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async cancelBookkeepingContract(businessId) {
        await post(`/business/${businessId}/home/bookkeepingContract/avoidance/cancel`);
        await _alert('해지가 취소되었습니다.');
        window.location.reload();
    }
    async avoidanceBookkeepingContract(businessId, param) {
        await post(`/business/${businessId}/home/bookkeepingContract/avoidance`, param);
        await _alert(`해지 예정 시간은 ${param.contractEndDate} 24:00입니다. 그 전까지는 해지 취소가 가능합니다.`);
        window.location.reload();
    }
    async fileDownload(url) {
        await downloadFileGet(url);
    }
}
export default new BusinessHomeService();