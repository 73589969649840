import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState, useMemo } from 'react';
import vm from './BusinessRegistrationManagementVm';
import PageNav from '@commonComponents/PageNav/PageNav';
import st from './BusinessRegistrationManagement.module.scss';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import BusinessName from '@pages/GridCommon/BusinessName/BusinessName';
import BusinessRegistrationProcessing from './ BusinessRegistrationProcessing/ BusinessRegistrationProcessing';
import gridLocale from '@common/module/gridLocale.ko.js';
import queryString from 'query-string';
import ArrayCellRenderer from '@pages/GridCommon/ArrayCellRenderer/ArrayCellRenderer';
import ObjectCellRenderer from '@pages/GridCommon/ObjectCellRenderer/ObjectCellRenderer';
import RowNumberRenderer from '@pages/GridCommon/RowNumberRenderer/RowNumberRenderer';

function BusinessRegistrationManagement() {
    const gridRef = useRef(); 
    let location = useLocation();
    const activeTab = queryString.parse(location.search).type;
    
    useEffect(() => {
        vm.load();
        vm.state.activeTab = activeTab || null;
    }, [activeTab])

    return useObserver(() => (
        <>
            <PageNav nav={["사업자등록", "사업자등록 연결"]}/>
                <div className={st.gridContent}>
                    <button className={st.pageArrowBtn} type="button" onClick={() => vm.openAlimtalkModal(gridRef)}>수동 알림톡 발송</button>
                    <ul className={st.tab}>
                        {vm.tabs.map((tab, index) => (
                            <li key={index} className={tab.businessRegistrationStatusType === vm.state.activeTab ? st.active : null} onClick={() => vm.onTab(tab.businessRegistrationStatusType)}>{tab.name}({tab.count})</li>
                        ))}
                    </ul>
                    {vm.columns.length !== 0 && 
                        <>
                            <Grid gridRef={gridRef} activeTab={vm.state.activeTab} key={activeTab}/>
                            <div className={st.gridPageSize}>
                                <span className={st.title}>Page Size</span> 
                                <select onChange={(e) => vm.onGridPageSizeChanged(e, gridRef)} defaultValue={"30"} className={st.selectedSmall}>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </>
                    }
                </div>
        </>
    ));
}
const Grid = React.memo(function (props) {
    const defaultColDef = useMemo(() => ({
        sortable: true,
        floatingFilter: true,
        resizable: true,
        cellEditorPopup : true
    }));
    const components = useMemo(() => ({
        "BusinessNameRenderer" : BusinessName,
        "BusinessProcessBtnRenderer" : BusinessRegistrationProcessing,
        "ArrayCellRenderer" : ArrayCellRenderer,
        "ObjectCellRenderer" : ObjectCellRenderer,
        "RowNumberRenderer" : RowNumberRenderer
    }), [])
    return useObserver(() => (
        <div className={clsx(st.grid, "ag-theme-alpine")}>
            <AgGridReact pagination={true} paginationPageSize={30}  suppressCsvExport={true} suppressExcelExport={true} rowSelection={'multiple'} cacheBlockSize={30} ref={props.gridRef} localeText={gridLocale} components={components} columnDefs={vm.columns} defaultColDef={defaultColDef} rowModelType={'serverSide'} serverSideDatasource={vm.gridData()} onCellEditingStopped={vm.onChangeData} onDragStopped={vm.onDragStopped} onColumnPinned={vm.onChangeData} onColumnVisible={vm.onChangeData} suppressBrowserResizeObserver={true}/>
        </div>
    ));
})
export default BusinessRegistrationManagement;