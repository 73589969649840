import { runInAction, observable, toJS } from "mobx";
import AlimtalkCenterService from '@service/AlimtalkCenterService';
import AlimtalkCenterModel from '@model/AlimtalkCenterModel';
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';

class AlimtalkCenterVm {
    constructor() {
        this.state = observable({
            keyword : ""
        })
    }    
    keyword(e) {
        this.state.keyword = e.target.value;
    }
    loadAlimtalkCenter() {
        AlimtalkCenterService.load(this.state.keyword);
    }
    async search(e) {
        e.preventDefault();
        this.loadAlimtalkCenter();
    }
    get AlimtalkCenterList() {
        return AlimtalkCenterModel.AlimtalkCenterList;
    }
    dayChange(e, index, type) {
        AlimtalkCenterModel.AlimtalkCenterList[index].alimtalkReservation[type].daysAgo = e.target.value;
        let param = {
            alimtalkType : type,
            type : "day",
            day : e.target.value
        }
        AlimtalkCenterService.saveAlimtalkCenterList(param, AlimtalkCenterModel.AlimtalkCenterList[index].businessId);
    }
    targetChange(e, index, index2, type) {
        AlimtalkCenterModel.AlimtalkCenterList[index].alimtalkReservation[type].targets[index2].checkedYn = e.target.checked;
        let param = {
            alimtalkType : type,
            type : "user",
            targets : AlimtalkCenterModel.AlimtalkCenterList[index].alimtalkReservation[type].targets.filter((target) => {
                return target.checkedYn === true
            }).map((checkedTarget) => {
                return checkedTarget.id
            })
        }
        AlimtalkCenterService.saveAlimtalkCenterList(param, AlimtalkCenterModel.AlimtalkCenterList[index].businessId);
    }
    openAlimtalkModal(businessId) {
        runInAction(() => {
            portalModel.title = "수동 알림톡 발송";
            portalModel.content = <AlimtalkSendModal businessId={businessId}/>;
        })
    }
}
export default new AlimtalkCenterVm();