import DelegationRegistrationProcessService from '@service/DelegationRegistrationProcessService';
import portalModel from '@model/portalModel';
import { runInAction, observable, toJS } from "mobx";
import CompanySearchModal from './CompanySearchModal/CompanySearchModal';
import DelegationRegistrationProcessModel from '@model/DelegationRegistrationProcessModel';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import { _alert, _confirm } from "@model/dialogModel";
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';
import CancelReason from "./CancelReason/CancelReason";

class ProcessVm {
    constructor() {
        this.state = observable({
            loadingYn: false,
            cancelReason : "",
        })
    }
    dataLoad(businessId) {
        DelegationRegistrationProcessService.load(businessId);
    }
    openCompanySearchModal() {
        runInAction(() => {
            portalModel.title = '조회하기';
            portalModel.content = <CompanySearchModal/>;
        })
    }
    get process() {
        return DelegationRegistrationProcessModel.process;
    }
    get bookkeepingServiceContract() {
        return DelegationRegistrationProcessModel.process.bookkeepingServiceContract;
    }
    get company() {
        return DelegationRegistrationProcessModel.process.company;
    }
    get businessRegistration() {
        return DelegationRegistrationProcessModel.process.businessRegistration;
    }
    customerType(e) {
        DelegationRegistrationProcessModel.process.bookkeepingServiceContract.customerType = e.target.value;
    }
    bookkeepingServiceStartAt(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        DelegationRegistrationProcessModel.process.bookkeepingServiceContract.bookkeepingServiceStartAt = date;
    }
    freeBookkeepingServiceYn(e) {
        const value = Number(e.target.value);
        const bookkeepingServiceStartAt = DelegationRegistrationProcessModel.process.bookkeepingServiceContract.bookkeepingServiceStartAt; 
        runInAction(() => {
            if(DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeMonth === value) {
                DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeMonth = null;
            } else {
                DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeMonth = value;
            }

            const freeMonth = DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeMonth;

            if(freeMonth && bookkeepingServiceStartAt) {
                let date = new Date(bookkeepingServiceStartAt);
                let returnDate = null;
                date.setMonth(date.getMonth() + freeMonth);
                date.setDate(date.getDate() - 1);
                const year = date.getFullYear();
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                returnDate = year+"-"+month+"-"+day;
                DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeBookkeepingServiceEndAt = returnDate;
            }else{
                DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeBookkeepingServiceEndAt = "";
            }            
        })
    }
    freeBookkeepingServiceEndAt(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        DelegationRegistrationProcessModel.process.bookkeepingServiceContract.freeBookkeepingServiceEndAt = date;
    }
    bookkeepingServiceFee(e) {
        DelegationRegistrationProcessModel.process.bookkeepingServiceContract.bookkeepingServiceFee = onlyNumber(removeCommas(e.target.value));
    }
    bookkeepingStaffId(e) {
        DelegationRegistrationProcessModel.process.bookkeepingServiceContract.bookkeepingStaffId = e.target.value;
    }
    taxationYn(e) {
        DelegationRegistrationProcessModel.process.company.taxationYn = e.target.value;
    }
    faithfulnessTaxPayerYn(e) {
        DelegationRegistrationProcessModel.process.company.faithfulnessTaxPayerYn = e.target.value;
    }
    paydayThisMonthYn(e) {
        DelegationRegistrationProcessModel.process.company.paydayThisMonthYn = e.target.value;
    }
    payday(e) {
        DelegationRegistrationProcessModel.process.company.payday = e.target.value;
    }
    vatPreliminaryReturn(e) {
        DelegationRegistrationProcessModel.process.company.vatPreliminaryReturn = e.target.value;
    }
    unregisteredYn(e) {
        DelegationRegistrationProcessModel.process.businessRegistration.unregisteredYn = e.target.checked;
    }
    businessRegistrationNumber(e) {
        DelegationRegistrationProcessModel.process.businessRegistration.businessRegistrationNumber = e.target.value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');;
    }
    businessStartAt(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        DelegationRegistrationProcessModel.process.businessRegistration.businessStartAt = date;
    }
    issuedAt(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        DelegationRegistrationProcessModel.process.businessRegistration.issuedAt = date;
    }
    businessType(e, index) {
        DelegationRegistrationProcessModel.process.businessRegistration.businessTypeAndItemList[index].type = e.target.value;
    }
    businessItem(e, index) {
        DelegationRegistrationProcessModel.process.businessRegistration.businessTypeAndItemList[index].item = e.target.value;
    }
    addBusinessTypeAndItem(index) {
        DelegationRegistrationProcessModel.process.businessRegistration.businessTypeAndItemList.push({
            type: "",
            item: ""
        })
    }
    removeBusinessTypeAndItem(index) {
        DelegationRegistrationProcessModel.process.businessRegistration.businessTypeAndItemList.splice(index, 1);
    }
    changeBusinessRegistrationFile(file) {
        DelegationRegistrationProcessModel.process.businessRegistration.businessRegistrationFile = file;
    }
    delegationRegistrationAlimTalkToList(e, index) {
        DelegationRegistrationProcessModel.process.delegationRegistrationAlimTalkToList[index].checkedYn = e.target.checked;
    }
    cmsAlimTalkToList(e, index) {
        DelegationRegistrationProcessModel.process.cmsAlimTalkToList[index].checkedYn = e.target.checked;
    }
    get cmsActive() {
        let active = true;
        active = DelegationRegistrationProcessModel.process.cmsAlimTalkToList.some((cmsAlimTalkToList) => {
            return cmsAlimTalkToList.checkedYn === true
        })
        return active;
    }
    get delegationRegistrationActive() {
        let active = true;
        if(!this.bookkeepingServiceContract.customerType) { //고객유형체크
            active = false;
        }
        if(!this.bookkeepingServiceContract.bookkeepingServiceStartAt) { //기장계약 개시일
            active = false;
        }
        if(this.bookkeepingServiceContract.freeMonth && !this.bookkeepingServiceContract.freeBookkeepingServiceEndAt) { //무료기장 종료일
            active = false;
        }
        if(!this.bookkeepingServiceContract.bookkeepingServiceFee) { //월기장료
            active = false;
        }
        if(!this.bookkeepingServiceContract.bookkeepingStaffId) { //담당자
            active = false;
        }
        if(this.company.taxationYn === "") { //과세/면세
            active = false;
        }
        if(this.company.faithfulnessTaxPayerYn === "") { //성실/일반
            active = false;
        }
        if(this.company.paydayThisMonthYn === "" || this.company.payday === "") { //급여지급일
            active = false;
        }
        if(!this.company.vatPreliminaryReturn) { //부가세 예정신고
            active = false;
        }
        if(!this.businessRegistration.unregisteredYn) { //사업자 등록되어있을때만 체크
            if(!this.businessRegistration.businessRegistrationNumber) { //사업자등록번호
                active = false;
            }
            if(!this.businessRegistration.businessStartAt) { //사업개시일
                active = false;
            }
            if(!this.businessRegistration.issuedAt) { //사업자등록증발급일
                active = false;
            }
            if(this.businessRegistration.businessTypeAndItemList.length === 0) {
                active = false;
            }
            if(this.businessRegistration.businessRegistrationFile === null) {
                active = false;
            }
        }
        const checkedYn = this.process.delegationRegistrationAlimTalkToList.some((delegationRegistrationAlimTalkToList) => { //알림톡 받으실분
            return delegationRegistrationAlimTalkToList.checkedYn === true
        })
        if(checkedYn === false) {
            active = false;
        }
        return active;
    }
    get delegationRegistrationParam() {
        let param = {
            bookkeepingServiceContract : {
                customerType : this.bookkeepingServiceContract.customerType,
                bookkeepingServiceStartAt : this.bookkeepingServiceContract.bookkeepingServiceStartAt,
                freeBookkeepingServiceEndAt : this.bookkeepingServiceContract.freeBookkeepingServiceEndAt,
                bookkeepingServiceFee : this.bookkeepingServiceContract.bookkeepingServiceFee,
                bookkeepingStaffId : this.bookkeepingServiceContract.bookkeepingStaffId,
                freeMonth : this.bookkeepingServiceContract.freeMonth
            },
            company : {
                taxationYn : this.company.taxationYn,
                faithfulnessTaxPayerYn : this.company.faithfulnessTaxPayerYn,
                paydayThisMonthYn : this.company.paydayThisMonthYn,
                payday : this.company.payday,
                vatPreliminaryReturn : this.company.vatPreliminaryReturn,
            },
            businessRegistration : {},
            delegationRegistrationAlimTalkTargets : this.process.delegationRegistrationAlimTalkToList.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            })
        }
        if(this.businessRegistration.unregisteredYn === true) {
            param.businessRegistration = {
                unregisteredYn : this.businessRegistration.unregisteredYn,
            }
        }else{
            param.businessRegistration = {
                businessRegistrationNumber : this.businessRegistration.businessRegistrationNumber,
                unregisteredYn : this.businessRegistration.unregisteredYn,
                businessStartAt : this.businessRegistration.businessStartAt,
                issuedAt : this.businessRegistration.issuedAt,
                businessTypeAndItemList: this.businessRegistration.businessTypeAndItemList
            }
            if(this.businessRegistration.businessRegistrationFile && !this.businessRegistration.businessRegistrationFile.url) {
                param.businessRegistration.businessRegistrationFile = this.businessRegistration.businessRegistrationFile;
            }
        }
        return param
    }
    async sendDelegationRegistration(param, businessId) {
        const _param = Object.assign(param, this.delegationRegistrationParam);
        try {
            this.state.loadingYn = true;
            await DelegationRegistrationProcessService.sendDelegationRegistration(_param, businessId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
    async saveDelegationRegistration(businessId) {
        const param = this.delegationRegistrationParam;
        try {
            this.state.loadingYn = true;
            await DelegationRegistrationProcessService.saveDelegationRegistration(param, businessId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
    async sendCmsAlimTalk(businessId) {
        const param = {
            alimTalkTargets : DelegationRegistrationProcessModel.process.cmsAlimTalkToList.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            })
        }
        try {
            this.state.loadingYn = true;
            await DelegationRegistrationProcessService.sendCmsAlimTalk(param, businessId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
    async cancleProcess(businessId, lastStep) {
        if(lastStep.indexOf('진행완료') !== -1) {
            return _alert('이용등록이 완료된 상태이기 때문에, 진행취소를 할 수 없어요.', '기장계약 관계가 종료되었다면 법인홈 화면에서 기장계약 해지 버튼을 눌러주세요.');
        }
        if(await _confirm(`${this.process.companyName}의 이용등록 진행을 취소하나요?`, '', '예', '아니오', 
        <>
            <CancelReason value={this.state.cancelReason} onChange={(e) => this.cancelReason(e)}/>
        </>)) {
            DelegationRegistrationProcessService.cancleProcess(businessId, this.state.cancelReason);
        }
    }
    cancelReason(e) {
        this.state.cancelReason = e.target.value;
    }
    openAlimtalkModal(businessId) {
        runInAction(() => {
            portalModel.title = "수동 알림톡 발송";
            portalModel.content = <AlimtalkSendModal businessId={businessId}/>;
        })
    }
}
export default new ProcessVm();