import ValueAddedTaxModel from '@model/ValueAddedTaxModel';
import ValueAddedTaxService from '@service/ValueAddedTaxService';
import {observable, toJS} from 'mobx';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber'

class PreliminaryReportVm {
    constructor() {
        this.state = observable({
            loadingYn: false
        })
    }
    get vatDetailDto() {
        return ValueAddedTaxModel.process.vatDetailDto;
    }
    get scheduledReportProgress() {
        return ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress;
    }
    get vatReturnCompleted() {
        return ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted;
    }
    get dataSubmissionAlimTalkToActive() {
        let active = true;
        active = this.scheduledReportProgress.dataSubmissionAlimTalkToList.some((item) => {
            return item.checkedYn === true
        })
        return active;
    }
    dataSubmissionAlimTalk(businessId) {
        const param = {
            alimTalkToIds : this.scheduledReportProgress.dataSubmissionAlimTalkToList.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            })
        }
        ValueAddedTaxService.sendTalk(param, businessId, ValueAddedTaxModel.process.vatId, 'VALUE_ADDED_TAX_GUIDELINES_FOR_DOCUMENT_SUBMISSION');
    }
    dataSubmissionAlimTalkToList(e, index) {
        this.scheduledReportProgress.dataSubmissionAlimTalkToList[index].checkedYn = e.target.checked;
    }

    get promptForDataSubmissionAlimTalkToActive() {
        let active = true;
        active = this.scheduledReportProgress.promptForDataSubmissionAlimTalkToList.some((item) => {
            return item.checkedYn === true
        })
        return active;
    }
    promptForDataSubmissionAlimTalk(businessId) {
        const param = {
            alimTalkToIds : this.scheduledReportProgress.promptForDataSubmissionAlimTalkToList.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            })
        }
        ValueAddedTaxService.sendTalk(param, businessId, ValueAddedTaxModel.process.vatId, 'VALUE_ADDED_TAX_REQUESTING_DOCUMENT_SUBMISSION');
    }
    promptForDataSubmissionAlimTalkToList(e, index) {
        this.scheduledReportProgress.promptForDataSubmissionAlimTalkToList[index].checkedYn = e.target.checked;
    }
    get readyToFileVatAlimTalkActive() {
        let active = true;
        active = this.scheduledReportProgress.readyToFileVatAlimTalkToList.some((item) => {
            return item.checkedYn === true
        })
        return active;
    }
    readyToFileVatAlimTalk(businessId) {
        const param = {
            alimTalkToIds : this.scheduledReportProgress.readyToFileVatAlimTalkToList.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            })
        }
        ValueAddedTaxService.sendTalk(param, businessId, ValueAddedTaxModel.process.vatId, 'VALUE_ADDED_TAX_DATA_COLLECTION_COMPLETE');
    }
    readyToFileVatAlimTalkToList(e, index) {
        this.scheduledReportProgress.readyToFileVatAlimTalkToList[index].checkedYn = e.target.checked;
    }
    changeStatementOfPayment(file) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.statementOfPayment = file;
    }
    changeStatementOfVatReturn(file) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.statementOfVatReturn = file;
    }
    changePurchaseAndSalesLedger(files, deleteYn, editYn) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.purchaseAndSalesLedger.files = files;
        if(deleteYn) {
            ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.purchaseAndSalesLedger.deleteYn = true;
        }
        if(editYn) {
            ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.purchaseAndSalesLedger.editYn = true;
        }
    }
    changeEtcFile(files, deleteYn, editYn) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.etcFile.files = files;
        if(deleteYn) {
            ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.etcFile.deleteYn = true;
        }
        if(editYn) {
            ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.etcFile.editYn = true;
        }
    }
    salesTax(e) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.vat.salesTax = onlyNumber(removeCommas(e.target.value));
    }
    purchaseTax(e) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.vat.purchaseTax = onlyNumber(removeCommas(e.target.value));
    }
    additionalTaxAmountPaid(e) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.vat.additionalTaxAmountPaid = onlyNumber(removeCommas(e.target.value));
    }
    get total() {
        const salesTax = parseInt(this.vatReturnCompleted.vat.salesTax) || 0;
        const purchaseTax = parseInt(this.vatReturnCompleted.vat.purchaseTax) || 0;
        const additionalTaxAmountPaid = parseInt(this.vatReturnCompleted.vat.additionalTaxAmountPaid) || 0;
        
        let _return = salesTax - purchaseTax + additionalTaxAmountPaid;
        return _return;
    }
    paymentPeriod(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }

        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.vat.paymentPeriod = date;
    }
    earlyRefundYn(e) {
        ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.vat.earlyRefundYn = e.target.checked;
    }
    alimTalkToList(e, index) {
        this.vatReturnCompleted.alimTalkToList[index].checkedYn = e.target.checked;
    }
    get activeVat() {
        let active = true;
        if(this.total > 0) {
            if(!this?.vatReturnCompleted?.statementOfPayment?.name) {
                active = false;
            }
        }
        if(!this?.vatReturnCompleted?.statementOfVatReturn?.name) {
            active = false;
        }
        if(!this.vatReturnCompleted.vat.salesTax && this.vatReturnCompleted.vat.salesTax !== 0) {
            active = false;
        }
        if(!this.vatReturnCompleted.vat.purchaseTax && this.vatReturnCompleted.vat.purchaseTax !== 0) {
            active = false;
        }
        if(!this.vatReturnCompleted.vat.paymentPeriod) {
            active = false;
        }
        const checkedYn = this.vatReturnCompleted.alimTalkToList.some((item) => { //알림톡 받으실분
            return item.checkedYn === true
        })
        if(checkedYn === false) {
            active = false;
        }
        return active;
    }
    get vatParam() {
        let param = {
            vatDetailDto : {
                returnCompletedType : "예정신고O",
                vatReturnCompleted : {
                    statementOfPayment : {
                        id : this.vatReturnCompleted.statementOfPayment?.id || null,
                        file : this.vatReturnCompleted.statementOfPayment?.id ? null : this.vatReturnCompleted.statementOfPayment
                    },
                    statementOfVatReturn : {
                        id : this.vatReturnCompleted.statementOfVatReturn?.id || null,
                        file : this.vatReturnCompleted.statementOfVatReturn?.id ? null : this.vatReturnCompleted.statementOfVatReturn
                    },
                    etcFile : {
                        deleteYn : this.vatReturnCompleted.etcFile?.deleteYn ? true : false,
                        files : this.vatReturnCompleted.etcFile?.editYn ? this.vatReturnCompleted.etcFile?.files : []
                    },
                    purchaseAndSalesLedger : {
                        deleteYn : this.vatReturnCompleted.purchaseAndSalesLedger?.deleteYn ? true : false,
                        files : this.vatReturnCompleted.purchaseAndSalesLedger?.editYn ? this.vatReturnCompleted.purchaseAndSalesLedger?.files : []
                    }
                },
                vat : {
                    salesTax : this.vatReturnCompleted.vat.salesTax,
                    purchaseTax : this.vatReturnCompleted.vat.purchaseTax,
                    additionalTaxAmountPaid : this.vatReturnCompleted.vat.additionalTaxAmountPaid,
                    paymentPeriod : {
                        year : this.vatReturnCompleted.vat.paymentPeriod.split('-')[0],
                        month : this.vatReturnCompleted.vat.paymentPeriod.split('-')[1],
                        day : this.vatReturnCompleted.vat.paymentPeriod.split('-')[2]
                    },
                    earlyRefundYn : this.vatReturnCompleted.vat.earlyRefundYn
                },
                alimTalkToIds : this.vatReturnCompleted.alimTalkToList.filter((person) => {
                    return person.checkedYn === true
                }).map((checkedPerson) => {
                    return checkedPerson.id
                })
            }
        }
        return param;
    }
    async sendVat(businessId) {
        try {
            this.state.loadingYn = true;
            await ValueAddedTaxService.sendVat(this.vatParam, businessId, ValueAddedTaxModel.process.vatId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
    async saveVat(businessId) {
        const param = Object.assign({}, this.vatParam);
        param.vatDetailDto.updateType = param.vatDetailDto.returnCompletedType;
        delete param.vatDetailDto.returnCompletedType;
        
        try {
            this.state.loadingYn = true;
            await ValueAddedTaxService.saveVat(param, businessId, ValueAddedTaxModel.process.vatId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
}
export default new PreliminaryReportVm();