import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from "react-router-dom";
import { useEffect } from 'react';
import loginVm from './LoginVm';
import st from './Login.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import useComponentWillMount from '@common/hook/useComponentWillMount';

let vm;

function Login() {
    useComponentWillMount(() => {
        vm =  new loginVm;
    })
    return useObserver(() => (
        <div className={st.login}>
            <form className={st.loginForm} onSubmit={(e) => vm.submit(e)}>
                <div className={st.logo}>
                    <img src='/images/loginLogo.svg'/>
                </div>
                <div className={st.inputRow}>
                    <div className={st.label}>
                        <label htmlFor="username">이메일</label>
                    </div>
                    <InputText id="username" fullSize value={vm.state.email} onChange={(e) => vm.changeEmail(e)} name="username" className={st.emailInput}/>
                </div>
                <div className={st.inputRow}>
                    <div className={st.label}>
                        <label htmlFor="password">비밀번호</label>
                    </div>
                    <InputText id="password" fullSize value={vm.state.password} onChange={(e) => vm.changePassword(e)} name="password" type="password"/>
                </div>
                <div className={st.autoLogin}>
                    <CheckBox checked={vm.state.autoLogin} onChange={(e) => vm.autoLogin(e)}>자동로그인</CheckBox>
                </div>
                <div className={st.btnArea}>
                    <SquareBtn className={st.loginBtn} type="submit">로그인</SquareBtn>
                </div>
                <div className={st.link}>
                    <Link to="/auth/FindPassword">비밀번호 찾기</Link><Link to="/auth/Join">회원가입</Link>
                </div>
            </form>
        </div>
    ));
}
export default Login;