import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import CorporateTaxListModel from '@model/CorporateTaxListModel';
import { pageRouter } from '@model/pageModel';

class CorporateTaxListService {
    async loadGridFilter(activeTab, selectedIndex) {
        const res = await get(`/corporateTax/grid/filter`);
        CorporateTaxListModel.setPeriodList(res.data.period.periodList);
        CorporateTaxListModel.setEmployeeList(res.data.employee.employeeList);
        CorporateTaxListModel.setSelectedUserId(res.data.employee.selectedUserId);
        if(!selectedIndex) {
            if(activeTab) {
                pageRouter.replace(`/corporateTax/list?type=${activeTab}&selectedIndex=${res.data.period.selectedIndex}`);
            }else{
                pageRouter.replace(`/corporateTax/list?selectedIndex=${res.data.period.selectedIndex}`);
            }
        }
    }
    async load() {
        const res = await get(`/corporateTax/grid/column`);
        CorporateTaxListModel.setColumns(res.data.columns);
    }
    async gridData(_params, period, selectedUserId) {
        let params = _params;
        params.year = period.value;
        params.staffId = selectedUserId;
        const res = await post('/corporateTax/grid', params);
        CorporateTaxListModel.setTabs(res.data.tabs);
        return res.data;
    }
    async onDragStopped(params) {
        await put('/corporateTax/grid/column/state', params);
    }
    async onChangeData(_params, period, selectedUserId) {
        let params = _params;
        params.year = period.value;
        params.staffId = selectedUserId;
        await put('/corporateTax/grid/column', params);
    }
}
export default new CorporateTaxListService();