import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import UserSettingModel from '@model/UserSettingModel';
import { _alert, _confirm } from "@model/dialogModel";
import userModel from '@model/userModel';

class UserSettingService {
    async load() {
        const res = await get('/my-account');
        UserSettingModel.setUserSetData(res.data);
    }
    async sendChangeEmail() {
        await post('/my-account/change-email');
    }
    async changePassword(param) {
        await post('/my-account/change-password', {
            password : param.password,
            newPassword : param.newPassword
        });
    }
    async changeName(param) {
        await post('/my-account/change-name', {
            name : param.name
        });
        userModel.user.name = param.name;
    }
    async changeMarketing(marketingAcceptYn) {
        await post('/my-account/change-marketing', {
            marketingTCAgreementId : UserSettingModel.userSetData.marketingTCAgreementId,
            marketingAcceptYn : marketingAcceptYn
        });
        this.load();
    }
    async changeMarketingDetail() {
        await post('/my-account/change-marketing-detail', {
            marketingTCAgreementId : UserSettingModel.userSetData.marketingTCAgreementId,
            marketingAcceptEmailYn : UserSettingModel.userSetData.marketingAcceptEmailYn
        });
        this.load();
    }
}
export default new UserSettingService();