import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import BusinessRegistrationModalVm from './BusinessRegistrationModalVm';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import st from './BusinessRegistrationModal.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import {toJS} from 'mobx';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import DragFileUpload from '@commonComponents/DragFileUpload/DragFileUpload';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import clsx from 'clsx';
import queryString from 'query-string';

let vm;

function BusinessRegistrationModal() {
    useComponentWillMount(() => {
        vm =  new BusinessRegistrationModalVm;
    })
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;

    return useObserver(() => (
        <div className={st.modalContent}>
            <ul>
                <li>
                    <CheckBox checked={vm.businessRegistration.unregisteredYn} onChange={(e) => vm.unregisteredYn(e)}>사업자등록 미등록상태</CheckBox>
                </li>
                {vm.businessRegistration.unregisteredYn !== true && 
                    <>
                        <li style={{marginTop:"24px"}}>
                            <div className={st.title}>사업자등록번호</div>
                            <div className={st.rowContent}>
                                <div>
                                    <InputText name="" maxLength="12" value={vm.businessRegistration.businessRegistrationNumber} onChange={(e) => vm.businessRegistrationNumber(e)} style={{width:"298px"}}/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={st.title}>사업개시일</div>
                            <div className={st.rowContent}>
                                <div className={st.datepicker}>
                                    <DatePicker name="" selected={vm.businessRegistration.businessStartAt} onChange={(e) => vm.businessStartAt(e)}/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={st.title}>업태, 종목</div>
                            <div className={st.rowContent}>
                                <div className={st.rowContent}>
                                    {vm.businessRegistration.businessTypeAndItemList.map((businessTypeAndItem, index) => (
                                        <div className={st.inputMany} key={index} style={{width:"298px"}}>
                                            <div className={st.businessTypeAndItem}>
                                                <div className={st.type}><span className={st.index}>{index+1}.</span><span>업태</span></div>
                                                <InputText name="" value={businessTypeAndItem.type} onChange={(e) => vm.businessType(e, index)} style={{width:'245px'}}/>
                                            </div>
                                            <div className={st.businessTypeAndItem}>
                                                <span className={st.item}>종목</span>
                                                <InputText name="" value={businessTypeAndItem.item} onChange={(e) => vm.businessItems(e, index)} style={{width:'245px'}}/> 
                                            </div>
                                            <PlusBtn className={st.plus} onClick={() => vm.addBusinessTypeAndItemList(index)}/>
                                            <MinusBtn className={st.minus} onClick={() => vm.removeBusinessTypeAndItemList(index)} disabled={vm.businessRegistration.businessTypeAndItemList.length <= 1}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={st.title} style={{top:"-8px"}}>사업자등록증<br/>발급일</div>
                            <div className={st.rowContent}>
                                <div className={st.datepicker}>
                                    <DatePicker name="" selected={vm.businessRegistration.issuedAt} onChange={(e) => vm.issuedAt(e)}/>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={st.title} style={{top:"-8px"}}>사업자등록증<br/>업로드</div>
                            <div className={st.rowContent}>
                                <DragFileUpload accept='.jpg, .jpeg, .png, .pdf' uploadFile={vm.businessRegistration.businessRegistrationFile} changeUploadFile={(e) => vm.changeBusinessRegistrationFile(e)} uploadBtnText={<><strong>사업자등록증</strong> 업로드</>}/>
                            </div>
                        </li>
                    </>
                }
                <li>
                    <div className={st.title} style={{top:"-4px"}}>알림톡 받으실 분</div>
                    <div className={st.rowContent}>
                        <ul className={st.checkList}>
                            {vm.alimtalkToList.map((person, index) => (
                                <li key={index}>
                                    <CheckBox checked={person.checkedYn} onChange={(e) => vm.changeAlimtalkToList(e, index)}>{person.name}님</CheckBox>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            </ul>
            <div className={st.buttonArea}>
                <div>
                    <button className={st.saveBtn} style={{width:"278px"}} disabled={!vm.saveActive} onClick={() => vm.save(businessId)}><span>변경저장</span></button>
                </div>
                <div style={{width:"278px"}}>
                    <button className={clsx(st.saveBtn, st.send)} disabled={!vm.saveActive} style={{width:"100%"}} onClick={() => vm.send(businessId)}><span>변경저장 + 알림톡 발송</span></button>
                    <div className={st.ex}>
                        사업자등록증이 바뀐 경우에만 이 버튼을 이용하세요.
                    </div>
                </div>
            </div>
        </div>
    ));
}
export default BusinessRegistrationModal;