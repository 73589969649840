import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';

function RowNumberRenderer(props) {
    return useObserver(() => (
        <span>
            {props.node.rowIndex + 1}
        </span>
    ));
}
export default RowNumberRenderer;