import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import NewCmsAccountContractModalVm from './NewCmsAccountContractModalVm';
import clsx from 'clsx';
import queryString from 'query-string';
import st from './NewCmsAccountContractModal.module.scss';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import { addCommas } from '@common/module/replaceNumber';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

let vm;

function NewCmsAccountContractModal() {
    useComponentWillMount(() => {
        vm =  new NewCmsAccountContractModalVm;
    })
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;

    return useObserver(() => (
        <div className={st.modalContent}>
            <ul>
                <li>
                    <div className={st.title} style={{top:"-4px"}}>알림톡 받으실 분</div>
                    <div className={st.rowContent}>
                        <ul className={st.checkList}>
                            {vm.data.alimtalkToList.map((person, index) => (
                                <li key={index}>
                                    <CheckBox checked={person.checkedYn} onChange={(e) => vm.changeAlimtalkToList(e, index)}>{person.name}님</CheckBox>
                                </li>
                            ))}
                        </ul>
                    </div>
                </li>
            </ul>
            <div className={st.buttonArea}>
                <div>
                    <button className={clsx(st.saveBtn, st.send)} disabled={!vm.saveActive} style={{width:"100%"}} onClick={() => vm.send(businessId)}><span>CMS계좌등록 + 계약서작성 알림톡 발송</span></button>
                </div>
            </div>
            <MessageHint text="알림톡 메세지보기" style={{marginTop:'8px'}}>
                <img src='/images/talkPreview_CMS_계약서새로작성.svg'/>
            </MessageHint>
        </div>
    ));
}
export default NewCmsAccountContractModal;