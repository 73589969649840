import { post, get, put, _delete } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import TimelineModel from '@model/TimelineModel';

class TimelineService {
    async load(businessId, timelineType=null) {
        let path=`/business/${businessId}/timeline?timelineType=${timelineType}`;
        if(!timelineType) {
            path=`/business/${businessId}/timeline`;
        }
        const res = await get(path);
        TimelineModel.setNormalTimeLines(res.data.normalTimeLines);
        TimelineModel.setPinnedTimeLines(res.data.pinnedTimeLines);
    }
    async saveMessage(param, businessId, activeTab) {
        const res = await post(`/business/${businessId}/timeline`, param);
        await this.load(businessId, activeTab);
    }
    async pin(businessId, timelineId, pinned, activeTab) {
        const res = await put(`/business/${businessId}/timeline/${timelineId}?pinned=${pinned}`);
        await this.load(businessId, activeTab);
    }
    async delete(businessId, timelineId, activeTab) {
        const res = await _delete(`/business/${businessId}/timeline/${timelineId}`);
        await this.load(businessId, activeTab);
    }
}
export default new TimelineService();