import CorporateTaxModel from '@model/CorporateTaxModel';
import CorporateTaxService from '@service/CorporateTaxService';
import { runInAction, observable, toJS } from "mobx";
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';

class ProcessVm {
    dataLoad(businessId, year) {
        CorporateTaxService.load(businessId, year);
    }
    get process() {
        return CorporateTaxModel.process;
    }
    openAlimtalkModal(businessId) {
        runInAction(() => {
            portalModel.title = "수동 알림톡 발송";
            portalModel.content = <AlimtalkSendModal businessId={businessId}/>;
        })
    }
    dataSubmission(e, index) {
        this.process.alimTalkToList.dataSubmission[index].checkedYn = e.target.checked;
    }
    get dataSubmissionActive() {
        let active = true;
        active = this.process.alimTalkToList.dataSubmission.some((item) => {
            return item.checkedYn === true
        })
        return active;
    }
    sendDataSubmission(businessId) {
        const param = {
            alimTalkToIds : this.process.alimTalkToList.dataSubmission.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            }),
            corporateTaxId : this.process.corporateTaxId
        }
        CorporateTaxService.sendTalk(param, businessId, 'dataSubmission');
    }
    requestingDocument(e, index) {
        this.process.alimTalkToList.requestingDocument[index].checkedYn = e.target.checked;
    }
    get requestingDocumentActive() {
        let active = true;
        active = this.process.alimTalkToList.requestingDocument.some((item) => {
            return item.checkedYn === true
        })
        return active;
    }
    sendRequestingDocument(businessId) {
        const param = {
            alimTalkToIds : this.process.alimTalkToList.requestingDocument.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            }),
            corporateTaxId : this.process.corporateTaxId
        }
        CorporateTaxService.sendTalk(param, businessId, 'requestingDocument');
    }
    taxBillSendWayType(e) {
        CorporateTaxModel.process.taxBillSendWayType = e.target.value;
    }
    settlementWork(e, index) {
        this.process.alimTalkToList.settlementWork[index].checkedYn = e.target.checked;
    }
    get settlementWorkActive() {
        let active = true;
        active = this.process.alimTalkToList.settlementWork.some((item) => {
            return item.checkedYn === true
        })
        return active;
    }
    sendSettlementWork(businessId) {
        const param = {
            alimTalkToIds : this.process.alimTalkToList.settlementWork.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            }),
            corporateTaxId : this.process.corporateTaxId
        }
        CorporateTaxService.sendTalk(param, businessId, 'settlementWork');
    }
    instalmentYn(e) {
        CorporateTaxModel.process.instalmentYn = e.target.value === "true";
    }
}
export default new ProcessVm();