import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState, useMemo } from 'react';
import vm from './DelegationRegistrationListVm';
import PageNav from '@commonComponents/PageNav/PageNav';
import st from './DelegationRegistrationList.module.scss';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import BusinessNameTimeline from '@pages/GridCommon/BusinessNameTimeline/BusinessNameTimeline';
import DelegationRegistrationProcessing from './DelegationRegistrationProcessing/DelegationRegistrationProcessing';
import gridLocale from '@common/module/gridLocale.ko.js';
import queryString from 'query-string';
import ArrayCellRenderer from '@pages/GridCommon/ArrayCellRenderer/ArrayCellRenderer';
import RowNumberRenderer from '@pages/GridCommon/RowNumberRenderer/RowNumberRenderer';

function DelegationRegistrationList() {
    const gridRef = useRef(); 
    let location = useLocation();
    const activeTab = queryString.parse(location.search).type;
    
    useEffect(() => {
        vm.load();
        vm.state.activeTab = activeTab || null;
    }, [activeTab])

    return useObserver(() => (
        <>
            <PageNav nav={["거래처", "스탠바이 이용등록"]}/>
            <div className={st.gridContent}>
                <button className={st.openProcess} type="button" onClick={() => vm.openWindow()}>이용등록</button>
                <button className={st.exportExcel} type="button" onClick={() => gridRef.current.api.exportDataAsExcel()}>엑셀로 내보내기</button>
                <ul className={st.tab}>
                    {vm.tabs.map((tab, index) => (
                        <li key={index} className={tab.delegationRegistrationStatusType === vm.state.activeTab ? st.active : null} onClick={() => vm.onTab(tab.delegationRegistrationStatusType)}>{tab.name}({tab.count})</li>
                    ))}
                </ul>
                {vm.columns.length !== 0 && 
                    <>
                        <Grid gridRef={gridRef} activeTab={vm.state.activeTab} key={activeTab}/>
                        <div className={st.gridPageSize}>
                            <span className={st.title}>Page Size</span> 
                            <select onChange={(e) => vm.onGridPageSizeChanged(e, gridRef)} defaultValue={"30"} className={st.selectedSmall}>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </>
                }
            </div>
        </>
    ));
}
const Grid = React.memo(function (props) {
    const defaultColDef = useMemo(() => ({
        sortable: true,
        floatingFilter: true,
        resizable: true
    }));
    const components = useMemo(() => ({
        "BusinessNameTimelineRenderer" : BusinessNameTimeline,
        "DelegationRegistrationBtnRenderer" : DelegationRegistrationProcessing,
        "ArrayCellRenderer" : ArrayCellRenderer,
        "RowNumberRenderer" : RowNumberRenderer
    }), [])
    return useObserver(() => (
        <div className={clsx(st.grid, "ag-theme-alpine")}>
            <AgGridReact pagination={true} paginationPageSize={30} cacheBlockSize={30} ref={props.gridRef} localeText={gridLocale} components={components} columnDefs={vm.columns} defaultColDef={defaultColDef} rowModelType={'serverSide'} serverSideDatasource={vm.gridData()} onCellEditingStopped={vm.onChangeData} onDragStopped={vm.onDragStopped} onColumnPinned={vm.onChangeData} onColumnVisible={vm.onChangeData} suppressBrowserResizeObserver={true}/>
        </div>
    ));
})
export default DelegationRegistrationList;