import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import { _alert, _confirm } from "@model/dialogModel";
import { pageRouter } from '@model/pageModel';

class FindPasswordService {
    async findPassword(data) { //비밀번호 찾기
        const res = await post(`/user/email/reset/password`, data, {});
        const findPasswordData = JSON.stringify(data);
    }
    async setupNewPassword(data, token) { //비밀번호 재설정
        const res = await post(`/user/reset/password`, data, {
            'Reset-Password-Authorization' :  `Bearer ${token}`
        });
        await _alert('비밀번호가 잘 변경되었어요!')
        pageRouter.replace(`/auth/login`);
    }
}
export default new FindPasswordService();