import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import vm from './PreNoticeVm';
import st from '../Process.module.scss';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import { addCommas } from '@common/module/replaceNumber';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import queryString from 'query-string';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

function PreNotice() {
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;
    return useObserver(() => (
        <li>
            <div className={listSt.ListTitle} style={{width:"190px"}}>부가세 고지세액</div>
            <div className={listSt.listContent}>
                <ul className={listSt.flexList}>
                    <li>
                        <div className={listSt.rowTitle} style={{top:"7px"}}>고지세액<span className={st.requiredMark}>*</span></div>
                        <div className={listSt.rowContent}>
                            <div className={st.inputs}>
                                <InputText name="" value={addCommas(vm.vatDetailDto.vat.taxAmountOfNotice)} onChange={vm.taxAmountOfNotice} style={{textAlign:"right"}}/>
                                <span className={st.inputSubText}>원</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.rowTitle} style={{top:"7px"}}>고지세액 납부기한<span className={st.requiredMark}>*</span></div>
                        <div className={listSt.rowContent}>
                            <DatePicker className={st.datePicker} name="" selected={vm.vatDetailDto.vat.dueDateForTaxAmountOfNotice} onChange={vm.dueDateForTaxAmountOfNotice}/>
                        </div>
                    </li>
                    <li className={listSt.hasBtn}>
                        <div className={listSt.rowTitle}>알림톡 받으실 분<span className={st.requiredMark}>*</span></div>
                        <div className={listSt.rowContent} style={{marginTop:"2px"}}>
                            <ul className={st.checkList}>
                                {vm.vatDetailDto.vat.alimTalkToList.map((person, index) => (
                                    <li key={index}>
                                        <CheckBox checked={person.checkedYn} onChange={(e) => vm.alimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                    <li className={listSt.hasBtn}>
                        <div>
                            <button style={{width:"576px"}} type='button' disabled={!vm.activeVat} className={listSt.sendBtn} onClick={() => vm.sendPreliminaryNoticeAmount(businessId)}><span>저장 + 부가세 고지세액 알림톡 발송</span></button>
                            <MessageHint text="알림톡 메세지보기" style={{width:'284px', display:'inline-block', marginLeft:"8px"}}>
                                <img src='/images/talkPreview_부가세고지세액.svg'/>
                            </MessageHint>    
                        </div>
                        <div className={listSt.saveFixBtn} style={{bottom:"0"}}>
                            <div className={listSt.btn}><button type="button" disabled={!vm.activeVat} onClick={() => vm.saveVat(businessId)}>수정사항 저장</button></div>
                            <div className={clsx(listSt.ex, !vm.activeVat ? listSt.disabled : null)}>저장시 고객화면 반영</div>
                        </div>
                    </li>
                </ul>
            </div>
        </li>
    ));
}
export default PreNotice;