import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import vm from './ProcessVm';
import queryString from 'query-string';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import st from './Process.module.scss';
import clsx from 'clsx';
import FinalPage from './FinalPage/FinalPage';
import PreNotice from './PreNotice/PreNotice';
import Fold from '@commonComponents/Fold/Fold';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import PreliminaryReport from './PreliminaryReport/PreliminaryReport';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

function Process() {
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;
    const period = queryString.parse(location.search).period;
    const year = queryString.parse(location.search).year;
    useEffect(() => {
        if(businessId) {
            vm.dataLoad(businessId, period, year);
        }
    }, [])
    return useObserver(() => (
        vm.process ?
        <div className={listSt.checkResultList}>
            <div className={listSt.result}>
                <div className={clsx(listSt.title, st.pageTitle)}>
                    부가세 업무 처리<span className={st.companyName}>{vm.vatDetailDto.companyName}</span>
                    <button className={clsx(listSt.pageArrowBtn, st.pageTopBtn)} type="button" onClick={() => vm.openAlimtalkModal(businessId)}>수동 알림톡 발송</button>
                </div>
                <ul>
                    <li>
                        <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{width:"190px"}}>현재상태</div>
                        <div className={listSt.listContent}>
                            <div>
                                {vm.vatDetailDto.status.replace('_',' ')}
                                {/* <MessageHint text="자동발송 알림톡 메세지보기(수정필요)" style={{marginTop:'20px'}}>
                                    <img src='/images/talkPreview_이용등록만.svg'/>
                                </MessageHint> */}
                            </div>
                        </div>
                    </li>
                    {vm.vatDetailDto.isFinalPage && 
                        <FinalPage/>
                    }
                    {vm.vatDetailDto.isFinalPage === false &&
                        <li>
                            <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{width:"190px"}}>예정신고 진행 선택</div>
                            <div className={listSt.listContent} style={{display:"block"}}>
                                {vm.vatDetailDto.scheduledReportProgressStatus === "예정신고X_예정고지X" && 
                                    <>
                                        <div className={st.selectedText}>예정신고 진행 X - 예정고지 X</div>
                                        <div className={listSt.ex} style={{marginTop:"3px"}}>
                                            알림톡은 자동으로 발송됩니다.
                                        </div>
                                    </>
                                }
                                {vm.vatDetailDto.scheduledReportProgressStatus === "예정신고X_예정고지O" && 
                                    <div className={st.selectedText}>예정신고 진행 X - 예정고지 O</div>
                                }
                                {vm.vatDetailDto.scheduledReportProgressStatus === "예정신고O" && 
                                    <div className={st.selectedText}>예정신고 진행 O</div>
                                }
                                {
                                    <ul style={{marginTop:"13px"}}>
                                        {vm.vatDetailDto.scheduledReportProgressStatus !== "예정신고X_예정고지X" &&
                                            <li>
                                                <button type="button" className={st.textBtn} onClick={() => vm.changeStatus(businessId, '예정신고X_예정고지X', '예정신고 진행 X - 예정고지 X')}>[예정신고 진행 X - 예정고지 X]로 변경</button>
                                            </li>
                                        }
                                        {vm.vatDetailDto.scheduledReportProgressStatus !== "예정신고X_예정고지O" &&
                                            <li>
                                                <button type="button" className={st.textBtn} onClick={() => vm.changeStatus(businessId, '예정신고X_예정고지O', '예정신고 진행 X - 예정고지 O')}>[예정신고 진행 X - 예정고지 O]로 변경</button>
                                            </li>
                                        }
                                        {vm.vatDetailDto.scheduledReportProgressStatus !== "예정신고O" &&
                                            <li>
                                                <button type="button" className={st.textBtn} onClick={() => vm.changeStatus(businessId, '예정신고O', '예정신고 진행 O')}>[예정신고 진행 O]로 변경</button>
                                            </li>
                                        }
                                    </ul>
                                }
                            </div>
                        </li>
                    }
                    {(vm.vatDetailDto.isFinalPage === false && vm.vatDetailDto.scheduledReportProgressStatus === "예정신고X_예정고지O") &&
                        <PreNotice/>
                    }
                    {(vm.vatDetailDto.isFinalPage === false && vm.vatDetailDto.scheduledReportProgressStatus === "예정신고O") &&
                        <PreliminaryReport/>
                    }
                </ul>
            </div>
        </div>
        :<></>
    ));
}
export default Process;