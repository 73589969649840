import { post, get, downloadFileGet, patch } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import BusinessRegistrationModel from '@model/BusinessRegistrationModel';
import { _alert, _confirm } from "@model/dialogModel";
import formDataToJson, {objectToFormData} from '@common/module/submit';

class BusinessRegistrationService {
    async load(businessId) {
        const res = await get(`/business/${businessId}/businessRegistration`);
        BusinessRegistrationModel.setBusinessRegistration(res.data.businessRegistration);
        BusinessRegistrationModel.setBusinessRegistrationProcessing(res.data.businessRegistrationProcessing);
    }
    async fileDownload(url) {
        await downloadFileGet(url);
    }
    async changeStatus(businessId, status, cancelReason) {
        let param = {
            status : status
        }
        if(cancelReason) {
            param.cancelReason = cancelReason;
        }
        const res = await patch(`/business/${businessId}/businessRegistration/status`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        if(status === "사업자등록_취소") {
            await _alert("사업자등록이 취소되었습니다.");
            window.close();
        }
        if(status === "열람기간_연장") {
            await _alert("열람기간이 연장되었습니다.");
            window.location.reload();
        }
    }
    async taxOfficeReceptionNotice(businessId) {
        const res = await post(`/business/${businessId}/businessRegistration/taxOfficeReceptionNotice`, this.taxOfficeReceptionParam(businessId));
    }
    async saveTaxOfficeReception(businessId) {
        const res = await post(`/business/${businessId}/businessRegistration/taxOfficeReception`, this.taxOfficeReceptionParam(businessId));
    }
    taxOfficeReceptionParam(businessId) {
        const businessRegistrationProcessing = BusinessRegistrationModel.businessRegistrationProcessing;
        let param = {
            alimTalkTargets : []
        }
        businessRegistrationProcessing.taxCertificateAlimTalkToList.forEach((taxCertificateAlimTalkToList) => {
            if(taxCertificateAlimTalkToList.checkedYn) {
                param.alimTalkTargets.push({
                    id : taxCertificateAlimTalkToList.id,
                    position : taxCertificateAlimTalkToList.position
                })
            }
        })
        const formData = new FormData();
        objectToFormData(formData, param);
        const file = businessRegistrationProcessing.taxCertificateFile;
        if(file && !file.url) {
            formData.append('receiptFile', file);
        }
        return formData;
    }
    async sendCompleteNotice(businessId) {
        const res = await post(`/business/${businessId}/businessRegistration/completeNotice`, this.completeParam(businessId));
    }
    async customerReceipt(businessId) {
        let param = {
            alimTalkTargets : []
        }
        const businessRegistrationProcessing = BusinessRegistrationModel.businessRegistrationProcessing;
        businessRegistrationProcessing.businessRegistrationCompletionAlimTalkToList.forEach((businessRegistrationCompletionAlimTalkToList) => {
            if(businessRegistrationCompletionAlimTalkToList.checkedYn) {
                param.alimTalkTargets.push({
                    id : businessRegistrationCompletionAlimTalkToList.id,
                    position : businessRegistrationCompletionAlimTalkToList.position
                })
            }
        })
        const res = await post(`/business/${businessId}/businessRegistration/completeNotice/customerReceipt`, param);
    }
    async saveComplete(businessId) {
        const res = await post(`/business/${businessId}/businessRegistration/complete`, this.completeParam(businessId));
    }
    completeParam(businessId) {
        const businessRegistrationProcessing = BusinessRegistrationModel.businessRegistrationProcessing;
        let param = {
            alimTalkTargets : [],
            businessRegistration : {
                issuedAt: businessRegistrationProcessing.issuedAt,
                businessRegistrationNumber: businessRegistrationProcessing.businessRegistrationNumber,
                dateOfBusinessCommencementByAccounting : businessRegistrationProcessing.dateOfBusinessCommencementByAccounting,
                businessTypeAndItemList: businessRegistrationProcessing.businessTypeAndItemList
            }
        }
        businessRegistrationProcessing.businessRegistrationCompletionAlimTalkToList.forEach((businessRegistrationCompletionAlimTalkToList) => {
            if(businessRegistrationCompletionAlimTalkToList.checkedYn) {
                param.alimTalkTargets.push({
                    id : businessRegistrationCompletionAlimTalkToList.id,
                    position : businessRegistrationCompletionAlimTalkToList.position
                })
            }
        })
        const formData = new FormData();
        objectToFormData(formData, param);
        const file = businessRegistrationProcessing.businessRegistrationCompletionFile;
        if(file && !file.url) {
            formData.append('businessRegistrationFile', file);
        }
        return formData;
    }
}
export default new BusinessRegistrationService();   