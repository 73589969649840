import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import DelegationRegistrationProcessModel from '@model/DelegationRegistrationProcessModel';
import { _alert, _confirm } from "@model/dialogModel";
import formDataToJson, {objectToFormData} from '@common/module/submit';

class DelegationRegistrationProcessService {
    async load(businessId) {
        const res = await get(`/business/${businessId}/delegationRegistration`);
        DelegationRegistrationProcessModel.setProcess(res.data);
    }
    async loadMetropolitansList() {
        const res = await get(`/metropolitans`);
        return res.data.selectableMetropolitanTypes;
    }
    async findBusiness(param) {
        const res = await get(`/business/delegationRegistration/findBusiness`, param);
        return res.data.businesses;
    }
    async cancleProcess(businessId, _cancelReason) {
        const res = await put(`/business/${businessId}/delegationRegistration/cancel`, {
            cancelReason: _cancelReason
        });
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('이용등록이 취소되었습니다.');
        window.close();
    }

    async sendDelegationRegistration(param,businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/delegationRegistration/request`, formData);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('이용등록 알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async saveDelegationRegistration(param,businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/delegationRegistration`, formData);
        await _alert('저장되었습니다.');
    }
    async sendCmsAlimTalk(param,businessId) {
        const res = await post(`/business/${businessId}/delegationRegistration/cmsAccountRegistration`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('CMS 알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
}
export default new DelegationRegistrationProcessService();