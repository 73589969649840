import DelegationRegistrationProcessService from '@service/DelegationRegistrationProcessService';
import { runInAction, observable, toJS } from "mobx";
import { _alert, _confirm } from "@model/dialogModel";

class CompanySearchModalVm {
    constructor() {
        this.state = observable({
            selectableMetropolitanTypes : [],
            metropolitanType : "",
            sigungu : "",
            companyName : "",
            businesses : null,
            selectedBusinessId : null
        })
    }
    async loadMetropolitansList() {
        this.state.selectableMetropolitanTypes = await DelegationRegistrationProcessService.loadMetropolitansList();
    }
    metropolitanType(e) {
        runInAction(() => {
            this.state.metropolitanType = e.target.value;
            this.state.sigungu = "";
        })
    }
    sigungu(e) {
        this.state.sigungu = e.target.value;
    }
    companyName(e) {
        this.state.companyName = e.target.value;
    }
    selectableSigungusList(metropolitanType){ 
        if(metropolitanType) {
            return this.state.selectableMetropolitanTypes.filter((selectableMetropolitanType) => {
                return selectableMetropolitanType.metropolitanType === metropolitanType
            })[0].selectableSigungus;
        }else{
            return [];
        }
    }
    async findBusiness(e) {
        e.preventDefault();
        if(!this.state.metropolitanType) {
            return await _alert('회사 본점 소재지를 선택하세요.');
        }
        if(!this.state.sigungu) {
            if(this.state.metropolitanType !== '서울특별시' && this.state.metropolitanType !== '세종특별자치시') {
                return await _alert('회사 본점 소재지를 선택하세요.');
            }
        }
        if(!this.state.companyName) {
            return await _alert('회사이름을 입력하세요.');
        }
        const param = {
            metropolitanType : this.state.metropolitanType,
            companyName : this.state.companyName
        }
        if(this.state.sigungu) {
            param.sigungu = this.state.sigungu
        }
        const result = await DelegationRegistrationProcessService.findBusiness(param);
        runInAction(() => {
            this.state.selectedBusinessId = null;
            this.state.businesses = result;
        })
    }
    selectedBusinessId(e) {
        this.state.selectedBusinessId = Number(e.target.value);
    }
    async selectBusiness() {
        if(!this.state.selectedBusinessId) {
            return await _alert('회사를 선택하세요');
        }
        window.location.href=`/delegationRegistration/process?businessId=${this.state.selectedBusinessId}`
    }
}
export default CompanySearchModalVm;