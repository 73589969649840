import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import st from './DelegationRegistrationProcessing.module.scss';
import vm from './DelegationRegistrationProcessingVm';

function  DelegationRegistrationProcessing(props) {
    return useObserver(() => (
        <button type='button' className={st.gridBtn} onClick={() => vm.openWindow(props.data.businessId)}>
            업무 처리
        </button>
    ));
}
export default  DelegationRegistrationProcessing;