import StaffListModel from '@model/StaffListModel';
import StaffListService from '@service/StaffListService';

class StaffListVm {
    load() {
        StaffListService.load();
    }
    get employees() {
        return StaffListModel.employees;
    }
}
export default new StaffListVm();