import { observable, runInAction, when } from 'mobx';

let dialogModel = observable({
    type: null, //dialog 종류: alert, confirm
    title: null,
    content: null,
    confirmYn: null,
    btn: {
        alertBtn: null,
        confirmBtn: null,
        cancelBtn: null
    },
    alertBtnText: null,
    confirmBtnText: null,
    cancelBtnText: null,
    contentJSX : null
});

const alertBtn = () => {
    runInAction(() => {
        dialogModel.type = null;
        dialogModel.title = null;
        dialogModel.content = null;
        dialogModel.confirmYn = null;
        dialogModel.btn = {
            alertBtn: null,
            confirmBtn: null,
            cancelBtn: null
        };
        dialogModel.alertBtnText = null;
        dialogModel.confirmBtnText = null;
        dialogModel.cancelBtnText = null;
        dialogModel.contentJSX = null;
    })
}

const confirmBtn = () => {
    runInAction(() => {
        dialogModel.type = null;
        dialogModel.title = null;
        dialogModel.content = null;
        dialogModel.confirmYn = true;
        dialogModel.btn = {
            alertBtn: null,
            confirmBtn: null,
            cancelBtn: null
        }
        dialogModel.alertBtnText = null;
        dialogModel.confirmBtnText = null;
        dialogModel.cancelBtnText = null;
        dialogModel.contentJSX = null;
    })
}

const cancelBtn = () => {
    runInAction(() => {
        dialogModel.type = null;
        dialogModel.title = null;
        dialogModel.content = null;        
        dialogModel.confirmYn = false;
        dialogModel.btn = {
            alertBtn: null,
            confirmBtn: null,
            cancelBtn: null
        }
        dialogModel.alertBtnText = null;
        dialogModel.confirmBtnText = null;
        dialogModel.cancelBtnText = null;
        dialogModel.contentJSX = null;
    })
}

const _alert = async (title, text, alertBtnText, contentJSX) => {
    runInAction(() => {
        dialogModel.type = 'alert';
        dialogModel.title = title;
        dialogModel.content = text;
        dialogModel.btn.alertBtn = alertBtn;
        dialogModel.alertBtnText = alertBtnText;
        dialogModel.contentJSX = contentJSX;;
    })
    await when(() => {
        return !dialogModel.type;
    })
}

const _confirm = async(title, text, confirmBtnText, cancelBtnText, contentJSX) => {
    runInAction(() => {
        dialogModel.type = 'confirm';
        dialogModel.title = title;
        dialogModel.content = text;
        dialogModel.btn.confirmBtn = confirmBtn;
        dialogModel.btn.cancelBtn = cancelBtn;
        dialogModel.confirmBtnText = confirmBtnText;
        dialogModel.cancelBtnText = cancelBtnText;
        dialogModel.contentJSX = contentJSX;
    })
    await when(() => {
        return !dialogModel.type;
    })
    return dialogModel.confirmYn;
}

export { dialogModel, _alert, _confirm, alertBtn, confirmBtn, cancelBtn};