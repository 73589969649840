import { useObserver } from 'mobx-react';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import vm from './ValueAddedTaxListVm';
import queryString from 'query-string';
import PageNav from '@commonComponents/PageNav/PageNav';
import { Route, withRouter, useLocation } from "react-router-dom";
import st from './ValueAddedTaxList.module.scss';
import BusinessName from '@pages/GridCommon/BusinessName/BusinessName';
import ValueAddedTaxProcessing from './ValueAddedTaxProcessing/ValueAddedTaxProcessing';
import { AgGridReact } from 'ag-grid-react';
import gridLocale from '@common/module/gridLocale.ko.js';
import clsx from 'clsx';
import RowNumberRenderer from '@pages/GridCommon/RowNumberRenderer/RowNumberRenderer';

function ValueAddedTaxList() {
    const gridRef = useRef(); 
    let location = useLocation();
    const activeTab = queryString.parse(location.search).type;
    const selectedIndex = queryString.parse(location.search).selectedIndex;
    useEffect(() => {
        return () => {
            vm.resetLoad();
        }
    }, [])
    useEffect(() => {
        if(selectedIndex && vm.periodList.length !== 0 && vm.selectedUserId) {
            vm.load();
        }else{
            vm.loadGridFilter(selectedIndex);
        }
        vm.state.activeTab = activeTab || null;
    }, [activeTab, selectedIndex, vm.periodList.length, vm.selectedUserId])

    return useObserver(() => (
        <>
            <PageNav nav={["세금", "부가세"]}/>
            <div className={st.gridContent}>
                <select value={selectedIndex} onChange={(e) => vm.selectedPeriodIdx(e, selectedIndex)} className={clsx(st.selectedPeriod, st.selectedSmall)}>
                    {vm.periodList.map((period, index) => (
                        <option key={index} value={index}>{period.name}</option>
                    ))}
                </select>
                {vm.selectedUserId && 
                    <select value={vm.selectedUserId} onChange={(e) => vm.changeSelectedUserId(e)} className={clsx(st.selectedEmployee, st.selectedSmall)}>
                        {vm.employeeList.map((employee, index) => (
                            <option key={index} value={employee.id}>{employee.name}</option>
                        ))}
                    </select>
                }
                <button className={st.exportExcel} type="button" onClick={() => gridRef.current.api.exportDataAsExcel()}>엑셀로 내보내기</button>
                <ul className={st.tab}>
                    {vm.tabs.map((tab, index) => (
                        <li key={index} className={tab.valueAddedTaxStatusType === vm.state.activeTab ? st.active : null} onClick={() => vm.onTab(tab.valueAddedTaxStatusType, selectedIndex)}>{tab.name}({tab.count})</li>
                    ))}
                </ul>
                {vm.columns.length !== 0 && vm.selectedUserId &&
                    <>
                        <Grid gridRef={gridRef} selectedIndex={selectedIndex} activeTab={vm.state.activeTab} key={activeTab+vm.selectedUserId}/>
                        <div className={st.gridPageSize}>
                            <span className={st.title}>Page Size</span> 
                            <select onChange={(e) => vm.onGridPageSizeChanged(e, gridRef)} defaultValue={"50"} className={st.selectedSmall}>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </>
                }
            </div>
        </>
    ));
}
const Grid = React.memo(function (props) {
    const defaultColDef = useMemo(() => ({
        sortable: true,
        floatingFilter: true,
        resizable: true
    }));
    const components = useMemo(() => ({
        "BusinessNameRenderer" : BusinessName,
        "ValueAddedTaxBtnRenderer" : ValueAddedTaxProcessing,
        "RowNumberRenderer" : RowNumberRenderer
    }), [])
    return useObserver(() => (
        <div className={clsx(st.grid, "ag-theme-alpine")}>
            <AgGridReact pagination={true} paginationPageSize={50} cacheBlockSize={50} ref={props.gridRef} localeText={gridLocale} components={components} columnDefs={vm.columns} defaultColDef={defaultColDef} rowModelType={'serverSide'} serverSideDatasource={vm.gridData(props.selectedIndex)} onCellEditingStopped={(e) => vm.onChangeData(e, props.selectedIndex)} onDragStopped={vm.onDragStopped} onColumnPinned={(e) => vm.onChangeData(e, props.selectedIndex)} onColumnVisible={(e) => vm.onChangeData(e, props.selectedIndex)} suppressBrowserResizeObserver={true}/>
        </div>
    ));
})
export default ValueAddedTaxList;