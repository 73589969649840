import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect, useState } from 'react';
import st from './MessageHint.module.scss';
import clsx from 'clsx';
import useComponentWillMount from '@common/hook/useComponentWillMount';

function MessageHint(props) {

    const [opened, setOpened] = useState(false);

    return useObserver(() => (
        <div className={st.hint} style={props.style}>
            <div className={clsx(st.title, opened ? st.opened : null)}>
                <button type='button' onClick={() => setOpened(!opened)}>{props.text}</button>
            </div>
            {opened && 
                <div className={st.content}>{props.children}</div>
            }
        </div>
    ));
}
export default MessageHint;