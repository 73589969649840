import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import ValueAddedTaxListModel from '@model/ValueAddedTaxListModel';
import { pageRouter } from '@model/pageModel';

class ValueAddedTaxListService {
    async loadGridFilter(activeTab, selectedIndex) {
        const res = await get(`/valueAddedTax/grid/filter`);
        ValueAddedTaxListModel.setPeriodList(res.data.period.periodList);
        ValueAddedTaxListModel.setEmployeeList(res.data.employee.employeeList);
        ValueAddedTaxListModel.setSelectedUserId(res.data.employee.selectedUserId);
        if(!selectedIndex) {
            if(activeTab) {
                pageRouter.replace(`/valueAddedTax/list?type=${activeTab}&selectedIndex=${res.data.period.selectedIndex}`);
            }else{
                pageRouter.replace(`/valueAddedTax/list?selectedIndex=${res.data.period.selectedIndex}`);
            }
        }
    }
    async load() {
        const res = await get(`/valueAddedTax/grid/column`);
        ValueAddedTaxListModel.setColumns(res.data.columns);
    }
    async gridData(_params, period, selectedUserId) {
        let params = _params;
        params.year = period.year;
        params.taxFilingPeriod = period.value;
        params.staffId = selectedUserId;
        const res = await post('/valueAddedTax/grid', params);
        ValueAddedTaxListModel.setTabs(res.data.tabs);
        return res.data;
    }
    async onDragStopped(params) {
        await put('/valueAddedTax/grid/column/state', params);
    }
    async onChangeData(_params, period, selectedUserId) {
        let params = _params;
        params.year = period.year;
        params.taxFilingPeriod = period.value;
        params.staffId = selectedUserId;
        await put('/valueAddedTax/grid/column', params);
    }
}
export default new ValueAddedTaxListService();