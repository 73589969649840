import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './CmsFileDownloadBtnRendererVm';
import st from './CmsFileDownloadBtnRenderer.module.scss';

function CmsFileDownloadBtnRenderer(props) {
    return useObserver(() => (
        props.data.cmsDirectDebitApplicationFileUrl ? 
            <button type='button' className={st.filedownLoad} onClick={() => vm.fileDown(props.data.cmsDirectDebitApplicationFileUrl)}>
                CMS 다운로드
            </button>
        : '-'
        
    ));
}
export default CmsFileDownloadBtnRenderer;