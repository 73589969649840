import { runInAction, observable, toJS } from "mobx";
import FindPasswordService from '@service/FindPasswordService';
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class SetupNewPasswordVm {
    constructor() {
        this.state = observable({
            password : "",
            passwordCheck : "",
        })
    }
    changePassword(e) {
        this.state.password = regExp.deleteSpace(e.target.value);
    }
    changePasswordCheck(e) {
        this.state.passwordCheck = regExp.deleteSpace(e.target.value);
    }
    async submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            if(param.passwordCheck) { //비밀번호 입력확인 데이터 삭제
                delete param.passwordCheck;
            }
            FindPasswordService.setupNewPassword(param, token);
        }
    }
}
export default new SetupNewPasswordVm();