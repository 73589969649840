import { observable } from 'mobx';

const ValueAddedTaxListModel = observable({
    columns : [],
    tabs : [],
    periodList : [],
    employeeList : [],
    selectedUserId : null,
    setColumns(columns) {
        this.columns = columns || []
    },
    setTabs(tabs) {
        this.tabs = tabs.map((tab) => {
            return {
                name : tab.name || "",
                valueAddedTaxStatusType : tab.valueAddedTaxStatusType || null,
                count : tab.count || 0
            }
        })
    },
    setPeriodList(periods) {
        this.periodList = periods.map((period) => {
            return {
                name : period.name || "",
                value : period.value || "",
                year : period.year || ""
            }
        })
    },
    setSelectedUserId(selectedUserId) {
        this.selectedUserId = selectedUserId || ""
    },
    setEmployeeList(employeeList) {
        this.employeeList = employeeList.map((employee) => {
            return {
                id : employee.id || "",
                name : employee.name || ""
            }
        })
    }
});

export default ValueAddedTaxListModel;