import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import AlimtalkSendModalModel from '@model/AlimtalkSendModalModel';
import { _alert, _confirm } from "@model/dialogModel";

class AlimtalkSendModalService {
    async load(businessId) {
        if(businessId) {
            const res = await get(`/alimtalk/targets/${businessId}`);
            AlimtalkSendModalModel.setAlimtalkSendData(res.data);
        }else{
            AlimtalkSendModalModel.setAlimtalkSendData();
        }
    }
    async send(businessId, businessIds) {
        if(businessId) {
            let param = {
                targets : AlimtalkSendModalModel.alimtalkSendData.selectedIds,
                content : AlimtalkSendModalModel.alimtalkSendData.content,
            }
            const res = await post(`/alimtalk/send/${businessId}`, param);
        }else{
            let param = {
                companyIds : businessIds,
                content : AlimtalkSendModalModel.alimtalkSendData.content,
            }
            const res = await post(`/alimtalk/send`, param);
        }
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
    }
}
export default new AlimtalkSendModalService();