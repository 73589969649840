import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 

class notificationService {
    async InviteTypeAccept(url, acceptYn, notificationId) {
        await post(url, {
            acceptYn : acceptYn,
            notificationId : notificationId
        })
    }
    async readNotification() {
        await post('/notification/read');
    }
}
export default new notificationService();