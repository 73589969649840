import './App.scss';
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { useEffect } from 'react';
import AppVm from './AppVm';
import { useObserver } from 'mobx-react';
import Auth from '@pages/Auth/Auth';
import Home from '@pages/Home/Home';
import Logined from '@pages/Logined/Logined';
import BusinessRegistrationManagement from '@pages/BusinessRegistrationManagement/BusinessRegistrationManagement';
import DelegationRegistrationList from '@pages/DelegationRegistration/DelegationRegistrationList';
import Popup from '@standby/common-ui/components/molecules/Popup/Popup';
import Dialog from '@standby/common-ui/components/molecules/Dialog/Dialog';
import BusinessRegistration from '@pages/BusinessRegistrationManagement/BusinessRegistration/BusinessRegistration';
import DelegationRegistrationProcess from '@pages/DelegationRegistration/Process/Process';
import Timeline from '@pages/Timeline/Timeline';
import ValueAddedTaxList from '@pages/ValueAddedTax/ValueAddedTaxList/ValueAddedTaxList';
import CorporateTaxList from '@pages/CorporateTax/CorporateTaxList/CorporateTaxList';
import ValueAddedTaxProcess from '@pages/ValueAddedTax/Process/Process';
import CorporateTaxProcess from '@pages/CorporateTax/Process/Process';
import AlimtalkCenter from '@pages/AlimtalkCenter/AlimtalkCenter';
import BusinessHome from '@pages/BusinessHome/BusinessHome';
import BusinessList from '@pages/BusinessList/BusinessList';
import StaffList from '@pages/StaffList/StaffList';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-048028 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( standbylab )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 September 2024 )____[v2]_MTcyNjcwMDQwMDAwMA==ddf0238f8ac3458090061890759fab84");

function App() {
  useEffect(() => {
    if(AppVm.isLogined) {
      AppVm.loginedStart();
    }
  }, [])
  return useObserver(() => (
    <div id="app">
      {AppVm.popupContent && 
        <Popup title={AppVm.popupTitle || ''} content={AppVm.popupContent} onClose={() => AppVm.popupClose()} portalModel={AppVm.portalModel}/>
      }
      {AppVm.dialogType &&
        <Dialog type={AppVm.dialogType} title={AppVm.dialogTitle} content={AppVm.dialogContent} btn={AppVm.dialogBtn} alertBtnText={AppVm.dialogAlertBtnText} confirmBtnText={AppVm.dialogConfirmBtnText} cancelBtnText={AppVm.dialogCancelBtnText} contentJSX={AppVm.dialogContentJSX}/>
      }
      <Routes>
        {/* 로그인없이 접근가능한 url */}
        {/*회원 인증관련 페이지 (로그인,회원가입,비번찾기등)*/}
        <Route path="/auth/*" element={AppVm.isLogined ? <Navigate to="/business/list"/> : <Auth/>}/>

        {/* 로그인후 접근가능한 url */}
        <Route path="/" element={<LoginedRenderCompoent component={<BusinessList/>}/>} exact/>
        <Route path="/myWork/Home" element={<LoginedRenderCompoent component={<Home/>}/>}/>
        <Route path="/BusinessRegistration/Management" element={<LoginedRenderCompoent component={<BusinessRegistrationManagement/>}/>}/>
        <Route path="/BusinessRegistration/BusinessRegistration" element={<LoginedRenderCompoentNotFrame component={<BusinessRegistration/>}/>}/>
        <Route path="/delegationRegistration/list" element={<LoginedRenderCompoent component={<DelegationRegistrationList/>}/>}/>
        <Route path="/valueAddedTax/list" element={<LoginedRenderCompoent component={<ValueAddedTaxList/>}/>}/>
        <Route path="/delegationRegistration/process" element={<LoginedRenderCompoentNotFrame component={<DelegationRegistrationProcess/>}/>}/>
        <Route path="/ValueAddedTax/process" element={<LoginedRenderCompoentNotFrame component={<ValueAddedTaxProcess/>}/>}/>
        <Route path="/timeline" element={<LoginedRenderCompoentNotFrame component={<Timeline/>}/>}/>
        <Route path="/alimtalkCenter/list" element={<LoginedRenderCompoent component={<AlimtalkCenter/>}/>}/>
        <Route path="/businessHome" element={<LoginedRenderCompoentNotFrame component={<BusinessHome/>}/>}/>
        <Route path="/business/list" element={<LoginedRenderCompoent component={<BusinessList/>}/>}/>
        <Route path="/staff/list" element={<LoginedRenderCompoent component={<StaffList/>}/>}/>
        <Route path="/corporateTax/list" element={<LoginedRenderCompoent component={<CorporateTaxList/>}/>}/>
        <Route path="/corporateTax/process" element={<LoginedRenderCompoentNotFrame component={<CorporateTaxProcess/>}/>}/>
      </Routes>
    </div>
  ));
}

function LoginedRenderCompoent(props) {
  return useObserver(() => (
    <>
      { (AppVm.user.name || AppVm.isLogined) ?
          <Logined>{props.component}</Logined>
        : 
          <Navigate to="/auth/login" replace={true}/>
      }
    </>
  ));
}

function LoginedRenderCompoentNotFrame(props) {
  return useObserver(() => (
    <>
      { (AppVm.user.name || AppVm.isLogined) ?
          <>{props.component}</>
        : 
          <Navigate to="/auth/login" replace={true}/>
      }
    </>
  ));
}

export default App;
