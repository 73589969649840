import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState, useMemo } from 'react';
import vm from './BusinessListVm';
import PageNav from '@commonComponents/PageNav/PageNav';
import st from './BusinessList.module.scss';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import BusinessName from '@pages/GridCommon/BusinessName/BusinessName';
import gridLocale from '@common/module/gridLocale.ko.js';
import queryString from 'query-string';
import RowNumberRenderer from '@pages/GridCommon/RowNumberRenderer/RowNumberRenderer';
import BookkeepingFileDownloadBtnRenderer from '@pages/GridCommon/BookkeepingFileDownloadBtnRenderer/BookkeepingFileDownloadBtnRenderer';
import CmsFileDownloadBtnRenderer from '@pages/GridCommon/CmsFileDownloadBtnRenderer/CmsFileDownloadBtnRenderer';

function BusinessList() {
    const gridRef = useRef(); 
    let location = useLocation();

    useEffect(() => {
        return () => {
            vm.resetLoad();
        }
    }, [])

    useEffect(() => {
        vm.load();
    }, [vm.selectedUserId])

    return useObserver(() => (
        <>
            <PageNav nav={["거래처", "거래처 목록"]}/>
            <div className={st.gridContent}>
                {vm.selectedUserId && 
                    <select value={vm.selectedUserId} onChange={(e) => vm.changeSelectedUserId(e)} className={clsx(st.selectedEmployee, st.selectedSmall)}>
                        {vm.employeeList.map((employee, index) => (
                            <option key={index} value={employee.id}>{employee.name}</option>
                        ))}
                    </select>
                }
                <button className={st.exportExcel} type="button" onClick={() => gridRef.current.api.exportDataAsExcel()}>엑셀로 내보내기</button>
                {vm.columns.length !== 0 && vm.selectedUserId &&
                    <>
                        <div style={{paddingTop:"44px"}}>
                            <Grid gridRef={gridRef}/>
                        </div>
                        <div className={st.gridPageSize}>
                            <span className={st.title}>Page Size</span> 
                            <select onChange={(e) => vm.onGridPageSizeChanged(e, gridRef)} defaultValue={"30"} className={st.selectedSmall}>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </>
                }
            </div>
        </>
    ));
}
const Grid = React.memo(function (props) {
    const defaultColDef = useMemo(() => ({
        sortable: true,
        floatingFilter: true,
        resizable: true
    }));
    const components = useMemo(() => ({
        "BusinessNameRenderer" : BusinessName,
        "RowNumberRenderer" : RowNumberRenderer,
        "BookkeepingFileDownloadBtnRenderer" : BookkeepingFileDownloadBtnRenderer,
        "CmsFileDownloadBtnRenderer" : CmsFileDownloadBtnRenderer
    }), [])
    return useObserver(() => (
        <div className={clsx(st.grid, "ag-theme-alpine")}>
            <AgGridReact pagination={true} paginationPageSize={30} cacheBlockSize={30} ref={props.gridRef} localeText={gridLocale} components={components} columnDefs={vm.columns} defaultColDef={defaultColDef} rowModelType={'serverSide'} serverSideDatasource={vm.gridData()} onCellEditingStopped={(e) => vm.onChangeData(e)} onDragStopped={vm.onDragStopped} onColumnPinned={(e) => vm.onChangeData(e)} onColumnVisible={(e) => vm.onChangeData(e)} suppressBrowserResizeObserver={true}/>
        </div>
    ));
})
export default BusinessList;