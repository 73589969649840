import CorporateTaxModel from '@model/CorporateTaxModel';
import CorporateTaxService from '@service/CorporateTaxService';
import { runInAction, observable, toJS } from "mobx";
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';

class NationalLocalTaxSendVm {
    constructor() {
        this.state = observable({
            loadingYn: false
        })
    }
    load(businessId, year) {
        CorporateTaxService.taxInfoLoad(businessId, year, "NATIONAL_LOCAL_TAX_SEND", CorporateTaxModel.process.instalmentYn);
    }
    get taxInfo() {
        return CorporateTaxModel.taxInfo;
    }
    get instalmentYn() {
        return CorporateTaxModel.process.instalmentYn;
    }
    changeNationalTax_1st(files, deleteYn, editYn) {
        CorporateTaxModel.taxInfo.nationalTax_1st.files = files;
        if(deleteYn) {
            CorporateTaxModel.taxInfo.nationalTax_1st.deleteYn = true;
        }
        if(editYn) {
            CorporateTaxModel.taxInfo.nationalTax_1st.editYn = true;
        }
    }
    changeNationalTax_2nd(files, deleteYn, editYn) {
        CorporateTaxModel.taxInfo.nationalTax_2nd.files = files;
        if(deleteYn) {
            CorporateTaxModel.taxInfo.nationalTax_2nd.deleteYn = true;
        }
        if(editYn) {
            CorporateTaxModel.taxInfo.nationalTax_2nd.editYn = true;
        }
    }
    changeLocalTaxFile(files, deleteYn, editYn) {
        CorporateTaxModel.taxInfo.localTaxFile.files = files;
        if(deleteYn) {
            CorporateTaxModel.taxInfo.localTaxFile.deleteYn = true;
        }
        if(editYn) {
            CorporateTaxModel.taxInfo.localTaxFile.editYn = true;
        }
    }
    changeNationalTaxFile(files, deleteYn, editYn) {
        CorporateTaxModel.taxInfo.nationalTaxFile.files = files;
        if(deleteYn) {
            CorporateTaxModel.taxInfo.nationalTaxFile.deleteYn = true;
        }
        if(editYn) {
            CorporateTaxModel.taxInfo.nationalTaxFile.editYn = true;
        }
    }
    changeStatementOfTaxAdjustment(files, deleteYn, editYn) {
        CorporateTaxModel.taxInfo.statementOfTaxAdjustment.files = files;
        if(deleteYn) {
            CorporateTaxModel.taxInfo.statementOfTaxAdjustment.deleteYn = true;
        }
        if(editYn) {
            CorporateTaxModel.taxInfo.statementOfTaxAdjustment.editYn = true;
        }
    }
    changeEtcFile(files, deleteYn, editYn) {
        CorporateTaxModel.taxInfo.etcFile.files = files;
        if(deleteYn) {
            CorporateTaxModel.taxInfo.etcFile.deleteYn = true;
        }
        if(editYn) {
            CorporateTaxModel.taxInfo.etcFile.editYn = true;
        }
    }
    changeFinancialStatementsConfirmation(file) {
        CorporateTaxModel.taxInfo.financialStatementsConfirmation = file;
    }
    finalTaxLiability(e) {
        CorporateTaxModel.taxInfo.nationalTax.finalTaxLiability = onlyNumber(removeCommas(e.target.value));
    }
    prePaidTax(e) {
        CorporateTaxModel.taxInfo.nationalTax.prePaidTax = onlyNumber(removeCommas(e.target.value));
    }
    additionalTaxPayable(e) {
        CorporateTaxModel.taxInfo.nationalTax.additionalTaxPayable = onlyNumber(removeCommas(e.target.value));
    }
    specialTaxForRuralDevelopment(e) {
        CorporateTaxModel.taxInfo.nationalTax.specialTaxForRuralDevelopment = onlyNumber(removeCommas(e.target.value));
    }
    get total() {
        const finalTaxLiability = parseInt(this.taxInfo.nationalTax.finalTaxLiability) || 0;
        const prePaidTax = parseInt(this.taxInfo.nationalTax.prePaidTax) || 0;
        const additionalTaxPayable = parseInt(this.taxInfo.nationalTax.additionalTaxPayable) || 0;
        const specialTaxForRuralDevelopment = parseInt(this.taxInfo.nationalTax.specialTaxForRuralDevelopment) || 0;
        
        let _return = finalTaxLiability - prePaidTax + additionalTaxPayable + specialTaxForRuralDevelopment
        return _return;
    }
    taxPayable_1st(e) {
        CorporateTaxModel.taxInfo.nationalTax.taxPayable_1st = onlyNumber(removeCommas(e.target.value));
    }
    dueDateForPayment_1st(e) {
        let date = null;
        if(e) {            
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }

        CorporateTaxModel.taxInfo.nationalTax.dueDateForPayment_1st = date;
    }
    taxPayable_2nd(e) {
        CorporateTaxModel.taxInfo.nationalTax.taxPayable_2nd = onlyNumber(removeCommas(e.target.value));
    }
    dueDateForPayment_2nd(e) {
        let date = null;
        if(e) {            
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }

        CorporateTaxModel.taxInfo.nationalTax.dueDateForPayment_2nd = date;
    }
    taxAmount(e) {
        CorporateTaxModel.taxInfo.localTax.taxAmount = onlyNumber(removeCommas(e.target.value));
    }
    nationalTaxDueDateForPayment(e) {
        let date = null;
        if(e) {            
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        CorporateTaxModel.taxInfo.nationalTax.dueDateForPayment = date;
    }
    localTaxDueDateForPayment(e) {
        let date = null;
        if(e) {            
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }

        CorporateTaxModel.taxInfo.localTax.dueDateForPayment = date;
    }
    alimTalkToList(e, index) {
        CorporateTaxModel.taxInfo.alimTalkToList[index].checkedYn = e.target.checked;
    }
    get activeCompletedNotice() {
        let active = true;
        if(this.instalmentYn === true) {
            if(!this.taxInfo.nationalTax_1st.files || this.taxInfo.nationalTax_1st.files.length === 0 ) {
                active = false;
            }
            if(!this.taxInfo.nationalTax_2nd.files || this.taxInfo.nationalTax_2nd.files.length === 0 ) {
                active = false;
            }
            if(!this.taxInfo.localTaxFile.files || this.taxInfo.localTaxFile.files.length === 0 ) {
                active = false;
            }
            if(!this.taxInfo.nationalTax.taxPayable_1st && this.taxInfo.nationalTax.taxPayable_1st !== 0) {
                active = false;
            }
            if(!this.taxInfo.nationalTax.dueDateForPayment_1st) {
                active = false;
            }
            if(!this.taxInfo.nationalTax.taxPayable_2nd && this.taxInfo.nationalTax.taxPayable_2nd !== 0) {
                active = false;
            }
            if(!this.taxInfo.nationalTax.dueDateForPayment_2nd) {
                active = false;
            }
        }else{
            if(!this.taxInfo.nationalTax.dueDateForPayment) {
                active = false;
            }
        }
        if(!this.taxInfo.statementOfTaxAdjustment.files || this.taxInfo.statementOfTaxAdjustment.files.length === 0 ) {
            active = false;
        }
        if(!this.taxInfo.nationalTax.finalTaxLiability && this.taxInfo.nationalTax.finalTaxLiability !== 0) {
            active = false;
        }
        if(!this.taxInfo.localTax.taxAmount && this.taxInfo.localTax.taxAmount !== 0) {
            active = false;
        }
        if(!this.taxInfo.localTax.dueDateForPayment) {
            active = false;
        }
        const checkedYn = this.taxInfo.alimTalkToList.some((item) => { //알림톡 받으실분
            return item.checkedYn === true
        })
        if(checkedYn === false) {
            active = false;
        }
        return active;
    }
    get completedNoticeParam() {
        const taxInfo = CorporateTaxModel.taxInfo;
        const process = CorporateTaxModel.process;

        let param = {
            corporateTaxId : process.corporateTaxId,
            instalmentYn : process.instalmentYn,
            localTaxFile : {
                deleteYn : taxInfo.localTaxFile?.deleteYn ? true : false,
                files : taxInfo.localTaxFile?.editYn ? taxInfo.localTaxFile?.files : []
            },
            statementOfTaxAdjustment : {
                deleteYn : taxInfo.statementOfTaxAdjustment?.deleteYn ? true : false,
                files : taxInfo.statementOfTaxAdjustment?.editYn ? taxInfo.statementOfTaxAdjustment?.files : []
            },
            etcFile : {
                deleteYn : taxInfo.etcFile?.deleteYn ? true : false,
                files : taxInfo.etcFile?.editYn ? taxInfo.etcFile?.files : []
            },
            financialStatementsConfirmation : {
                id : taxInfo.financialStatementsConfirmation?.id || null
            },
            instalmentYn : process.instalmentYn,
            nationalTax : taxInfo.nationalTax,
            localTax : taxInfo.localTax,
            alimTalkTargets : taxInfo.alimTalkToList.filter((person) => {
                return person.checkedYn === true
            }).map((checkedPerson) => {
                return checkedPerson.id
            })
        }
        if(!taxInfo.financialStatementsConfirmation?.id && taxInfo.financialStatementsConfirmation) {
            param.financialStatementsConfirmation.file = taxInfo.financialStatementsConfirmation
        }
        if(process.instalmentYn) {
            param.nationalTax_1st = {
                deleteYn : taxInfo.nationalTax_1st?.deleteYn ? true : false,
                files : taxInfo.nationalTax_1st?.editYn ? taxInfo.nationalTax_1st?.files : []
            };
            param.nationalTax_2nd = {
                deleteYn : taxInfo.nationalTax_2nd?.deleteYn ? true : false,
                files : taxInfo.nationalTax_2nd?.editYn ? taxInfo.nationalTax_2nd?.files : []
            };
        }else{
            param.nationalTaxFile = {
                deleteYn : taxInfo.nationalTaxFile?.deleteYn ? true : false,
                files : taxInfo.nationalTaxFile?.editYn ? taxInfo.nationalTaxFile?.files : []
            }
        }
        return param;
    }
    async sendCompletedNotice(businessId) {
        try {
            this.state.loadingYn = true;
            await CorporateTaxService.sendCompletedNotice(this.completedNoticeParam, businessId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
    async saveCompletedNotice(businessId) {
        try {
            this.state.loadingYn = true;
            await CorporateTaxService.saveCompletedNotice(this.completedNoticeParam, businessId);
            this.state.loadingYn = false;
        } catch(err) {
            this.state.loadingYn = false;
        }
    }
}
export default new NationalLocalTaxSendVm();