import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './AlimtalkCenterVm';
import PageNav from '@commonComponents/PageNav/PageNav';
import st from './AlimtalkCenter.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';

function AlimtalkCenter() {
    useEffect(() => {
        vm.loadAlimtalkCenter();
    }, [])
    return useObserver(() => (
        <>
            <PageNav nav={["알림톡 센터", "자동발송 일정관리"]}/>
            <form className={st.searchBox} onSubmit={(e) => vm.search(e)}>
                <input type="text" className={st.searchInput} value={vm.state.keyword} onChange={(e) => vm.keyword(e)}/>
                <button type="submit" className={st.submit}>검색</button>
            </form>
            <table className={st.alimtalkCenterList}>
                <colgroup>
                    <col/>
                    <col style={{width:"162px"}}/>
                    <col style={{width:"157px"}}/>
                    <col style={{width:"252px"}}/>
                    {/* <col style={{width:"182px"}}/>
                    <col style={{width:"252px"}}/> */}
                    <col style={{width:"182px"}}/>
                    <col style={{width:"252px"}}/>
                    <col style={{width:"182px"}}/>
                </colgroup>
                <thead>
                    <tr>
                        <th rowSpan="2" style={{textAlign:"left", paddingLeft:"19px"}}>거래처 명</th>
                        <th rowSpan="2" style={{textAlign:"left", paddingLeft:"19px"}}>고객유형</th>
                        <th rowSpan="2">수동 알림톡 발송</th>
                        {/* <th colSpan="2" className={st.mainTh} style={{backgroundColor:"#B6E3ED"}}>급여대장</th> */}
                        <th colSpan="2" className={st.mainTh} style={{backgroundColor:"#F1D9B4"}}>부가세</th>
                        <th colSpan="2" className={st.mainTh} style={{backgroundColor:"#A6E4D9"}}>법인세</th>
                    </tr>
                    <tr>
                        {/* <th className={st.subTh} style={{backgroundColor:"#E9F7FA"}}>입력요청알림</th>
                        <th className={st.subTh} style={{backgroundColor:"#E9F7FA"}}>메세지 받을 사람</th> */}
                        <th className={st.subTh} style={{backgroundColor:"#FBF4E8"}}>부가세 신고준비 알림</th>
                        <th className={st.subTh} style={{backgroundColor:"#FBF4E8"}}>메세지 받을 사람</th>
                        <th className={st.subTh} style={{backgroundColor:"#E4F7F4"}}>법인세 신고준비 알림</th>
                        <th className={st.subTh} style={{backgroundColor:"#E4F7F4"}}>메세지 받을 사람</th>
                    </tr>
                </thead>
                <tbody>
                    {vm.AlimtalkCenterList.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.businessName}</td>
                            <td>{item?.customerType}</td>
                            <td style={{textAlign:"center"}}>
                                <button type="button" className={st.sendBtn} onClick={() => vm.openAlimtalkModal(item?.businessId)}>발송하기</button>
                            </td>
                            {/* <td style={{padding: "5px 12px"}}>
                                <select className={st.select} value={item.alimtalkReservation.PAYROLL.daysAgo} onChange={(e) => vm.dayChange(e, index, "PAYROLL")}>
                                    {[...Array(parseInt(9))].map((n, index2) => (
                                        <option key={index2} value={index2+2}>저장된 급여일로부터 {index2+2}영업일 전</option>
                                    ))}
                                </select>
                            </td>
                            <td style={{padding: "5px 12px"}}>
                                <ul className={st.targetList}>
                                    {item.alimtalkReservation.PAYROLL.targets.map((target, index2) => (
                                        <li key={index2}><CheckBox value={target.id} checked={target.checkedYn} onChange={(e) => vm.targetChange(e, index, index2, "PAYROLL")}>{target.name}</CheckBox></li>
                                    ))}
                                </ul>
                            </td> */}
                            <td style={{padding: "5px 12px"}}>
                                <select className={st.select} style={{width:"225px"}} value={item.alimtalkReservation.PREPARATION_FOR_VALUE_ADDED_TAX_FILING.daysAgo} onChange={(e) => vm.dayChange(e, index, "PREPARATION_FOR_VALUE_ADDED_TAX_FILING")}>
                                    {[...Array(parseInt(21))].map((n, index2) => (
                                        <option key={index2} value={index2+10}>부가세 납부기한으로부터 {index2+10}일 전</option>
                                    ))}
                                </select>
                            </td>
                            <td style={{padding: "5px 12px"}}>
                                <ul className={st.targetList}>
                                    {item.alimtalkReservation.PREPARATION_FOR_VALUE_ADDED_TAX_FILING.targets.map((target, index2) => (
                                        <li key={index2}><CheckBox value={target.id} checked={target.checkedYn} onChange={(e) => vm.targetChange(e, index, index2, "PREPARATION_FOR_VALUE_ADDED_TAX_FILING")}>{target.name}</CheckBox></li>
                                    ))}
                                </ul>
                            </td>
                            <td style={{padding: "5px 12px"}}>
                                <select className={st.select} style={{width:"225px"}} value={item.alimtalkReservation.PREPARATION_FOR_CORPORATE_TAX_FILING.daysAgo} onChange={(e) => vm.dayChange(e, index, "PREPARATION_FOR_CORPORATE_TAX_FILING")}>
                                    {[...Array(parseInt(26))].map((n, index2) => (
                                        <option key={index2} value={index2+25}>법인세 납부기한으로부터 {index2+25}일 전</option>
                                    ))}
                                </select>
                            </td>
                            <td style={{padding: "5px 12px"}}>
                            <ul className={st.targetList}>
                                    {item.alimtalkReservation.PREPARATION_FOR_CORPORATE_TAX_FILING.targets.map((target, index2) => (
                                        <li key={index2}><CheckBox value={target.id} checked={target.checkedYn} onChange={(e) => vm.targetChange(e, index, index2, "PREPARATION_FOR_CORPORATE_TAX_FILING")}>{target.name}</CheckBox></li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    ));
}
export default AlimtalkCenter;