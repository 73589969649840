import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import vm from './BusinessRegistrationVm';
import queryString from 'query-string';
import st from './BusinessRegistration.module.scss';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import DragFileUpload from '@commonComponents/DragFileUpload/DragFileUpload';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import MessageHint from '@commonComponents/MessageHint/MessageHint';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import copyText from '@common/module/copyText';
import clsx from 'clsx';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import { addCommas } from '@common/module/replaceNumber';
import Loading from '@standby/common-ui/components/atoms/Loading';

function BusinessRegistration(props) {
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;
    useEffect(() => {
        vm.dataLoad(businessId);
    }, [])
    return useObserver(() => (
        <div className={listSt.checkResultList}>
            {vm.state.loadingYn && <Loading/>}
            <div className={listSt.result}>
                <div className={clsx(listSt.title, st.pageTitle)}>
                    사업자등록 처리
                    <button className={clsx(listSt.pageArrowBtn, st.pageTopBtn)} type="button" onClick={() => vm.openAlimtalkModal(businessId)}>수동 알림톡 발송</button>
                </div>
                <ul>
                    <li>
                        <div className={listSt.ListTitle} style={{paddingRight:'16px'}}>{vm.businessRegistration.companyName}</div>
                        <div className={listSt.listContent}>
                            <div className={listSt.innerContent}>
                                <div className={st.result}>
                                    <span style={{marginRight:"10px"}}>{vm.statusText(vm.businessRegistrationProcessing.status)}</span>
                                    {vm.businessRegistrationProcessing.viewingPeriodExtensionYn ? 
                                        <span style={{fontSize:"14px", fontWeight:"400"}}>[열람기간 3개월 연장됨 : ~{vm.businessRegistrationProcessing.viewingPeriod}까지]</span>
                                        :
                                        <span style={{fontSize:"14px", fontWeight:"400"}}>[열람기간 : ~{vm.businessRegistrationProcessing.viewingPeriod}까지]</span>
                                    }
                                    <div className={st.btnArea}>
                                        {(vm.businessRegistrationProcessing.status !== "사업자등록_취소" && vm.businessRegistrationProcessing.status !== "사업자등록_완료") && 
                                            <button type="button" onClick={() => vm.changeStatus(businessId, '사업자등록_취소')}>진행취소</button>
                                        }
                                        <button type="button" onClick={() => vm.changeStatus(businessId, '열람기간_연장')} disabled={vm.businessRegistrationProcessing.viewingPeriodExtensionYn}>열람기간 3개월 연장</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.listContent}>
                            <div className={listSt.innerTitle}>
                                세무서 접수
                            </div>
                            <div className={listSt.innerContent}>
                                <div className={listSt.talk}>
                                    <DragFileUpload uploadFile={vm.businessRegistrationProcessing.taxCertificateFile} changeUploadFile={vm.changeTaxCertificateFile} uploadBtnText={<><strong>접수증</strong> 업로드</>} uploadExplain="세무서 접수증을 업로드하면 발송버튼이 활성화됩니다."/>
                                    <div className={listSt.title}>알림톡 받으실 분</div>
                                    <ul className={listSt.sendList}>
                                        {vm.businessRegistrationProcessing.taxCertificateAlimTalkToList.map((person, index) => (
                                            <li key={index}><CheckBox checked={person.checkedYn} onChange={(e) => vm.taxCertificateAlimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox></li>
                                        ))}
                                    </ul>
                                    <button type='button' disabled={!vm.taxCertificateAlimTalkActive} className={listSt.sendBtn} onClick={() => vm.taxOfficeReceptionNotice(businessId)}><span>저장 + 세무서 접수안내 알림톡 발송</span></button>
                                    <MessageHint text="알림톡 메세지보기">
                                        <img src='/images/talkPreview1.svg'/>
                                    </MessageHint>
                                </div>
                                <div className={listSt.saveFixBtn}>
                                    <div className={listSt.btn}><button type="button" disabled={!vm.taxCertificateAlimTalkActive} onClick={() => vm.saveTaxOfficeReception(businessId)}>수정사항 저장</button></div>
                                    <div className={clsx(listSt.ex, !vm.taxCertificateAlimTalkActive ? listSt.disabled : null)}>저장시 고객화면 반영</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.listContent}>
                            <div className={listSt.innerTitle}>
                                사업자등록 완료
                            </div>
                            <div className={listSt.innerContent}>
                                <div className={listSt.inputs}>
                                    <div className={listSt.inputRow} style={{marginBottom: '18px'}}>
                                        <CheckBox checked={vm.businessRegistrationProcessing.customerDirectReceiptYn} onChange={(e) => vm.customerDirectReceiptYn(e)}>사업자등록증 고객 직접수령</CheckBox>
                                    </div>
                                    {vm.businessRegistrationProcessing.customerDirectReceiptYn === false && 
                                        <>
                                            <div className={listSt.inputRow}>
                                                <div className={listSt.title}>사업자등록 번호</div>
                                                <div className={listSt.content}>
                                                    <InputText name="" maxLength="12" value={vm.businessRegistrationProcessing.businessRegistrationNumber} onChange={vm.businessRegistrationNumber} />
                                                </div>
                                            </div>
                                            <div className={listSt.inputRow}>
                                                <div className={listSt.title}>사업개시일</div>
                                                <div className={clsx(listSt.content, st.content)}>
                                                    <DatePicker name="" selected={vm.businessRegistrationProcessing.dateOfBusinessCommencementByAccounting} onChange={vm.dateOfBusinessCommencementByAccounting} />
                                                </div>
                                            </div>
                                            <div className={listSt.inputRow}>
                                                <div className={listSt.title}>업태, 종목</div>
                                                <div className={listSt.content} style={{marginTop: '-12px'}}>
                                                    {vm.businessRegistrationProcessing.businessTypeAndItemList.map((businessTypeAndItem, index) => (
                                                        <div className={listSt.inputMany} key={index}>
                                                            <div className={st.businessTypeAndItem}>
                                                                <div className={st.type}><span className={st.index}>{index+1}.</span><span>업태</span></div>
                                                                <InputText name="" value={businessTypeAndItem.type} onChange={(e) => vm.businessType(e, index)}/>
                                                            </div>
                                                            <div className={st.businessTypeAndItem}>
                                                                <span className={st.item}>종목</span>
                                                                <InputText name="" value={businessTypeAndItem.item} onChange={(e) => vm.businessItem(e, index)}/>   
                                                            </div>
                                                            <PlusBtn className={listSt.plus} onClick={() => vm.addBusinessTypeAndItem(index)}/>
                                                            <MinusBtn className={listSt.minus} onClick={() => vm.removeBusinessTypeAndItem(index)} disabled={vm.businessRegistrationProcessing.businessTypeAndItemList.length <= 1}/>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={listSt.inputRow}>
                                                <div className={listSt.title}>사업자등록증 발급일</div>
                                                <div className={listSt.content}>
                                                    <DatePicker name="" selected={vm.businessRegistrationProcessing.issuedAt} onChange={vm.issuedAt}/>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={listSt.talk} style={{marginTop:"24px"}}>
                                    {vm.businessRegistrationProcessing.customerDirectReceiptYn === false && 
                                        <DragFileUpload accept='.jpg, .jpeg, .png, .pdf' uploadFile={vm.businessRegistrationProcessing.businessRegistrationCompletionFile} changeUploadFile={vm.changeBusinessRegistrationCompletionFile} uploadBtnText={<><strong>사업자등록증</strong> 업로드</>} uploadExplain={<span>사업자등록정보를 입력하고, 사업자등록증을 업로드하면{<br/>}발송버튼이 활성화됩니다.</span>}/>
                                    }
                                    <div className={listSt.title}>알림톡 받으실 분</div>
                                    <ul className={listSt.sendList}>
                                        {vm.businessRegistrationProcessing.businessRegistrationCompletionAlimTalkToList.map((person, index) => (
                                            <li key={index}><CheckBox checked={person.checkedYn} onChange={(e) => vm.businessRegistrationCompletionAlimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox></li>
                                        ))}
                                    </ul>
                                    {vm.businessRegistrationProcessing.customerDirectReceiptYn === false && 
                                        <>
                                            <button type='button' disabled={!vm.completeNoticeActive} className={listSt.sendBtn} onClick={() => vm.sendCompleteNotice(businessId)}><span>저장 + 사업자등록 완료안내 알림톡 발송</span></button>
                                            <MessageHint text="알림톡 메세지보기">
                                                <img src='/images/talkPreview2.svg'/>
                                            </MessageHint>
                                        </>
                                    }
                                    {vm.businessRegistrationProcessing.customerDirectReceiptYn === true && 
                                        <>
                                            <button type='button' disabled={!vm.completeNoticeActive} className={listSt.sendBtn} onClick={() => vm.customerReceipt(businessId)}><span>저장 + 사업자등록증 고객수령 알림톡 발송</span></button>
                                            <MessageHint text="알림톡 메세지보기">
                                                <img src='/images/talkPreview3.svg'/>
                                            </MessageHint>
                                        </>
                                    }
                                </div>
                                <div className={listSt.saveFixBtn}>
                                    <div className={listSt.btn}><button type="button" disabled={!vm.completeNoticeActive} onClick={() => vm.saveComplete(businessId)}>수정사항 저장</button></div>
                                    <div className={clsx(listSt.ex, !vm.completeNoticeActive ? listSt.disabled : null)}>저장시 고객화면 반영</div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.listContent} style={{justifyContent:"end"}}>
                            <button className={st.openProcess} type="button" onClick={() => vm.openDelegationRegistrationWindow(businessId)}>이용등록</button>
                        </div>
                    </li>
                </ul>
            </div>
            <div className={listSt.result}>
                <div className={listSt.title}>
                    사업자등록 정보
                    <span className={listSt.subInfo}>파란색 글씨를 클릭하면 바로 복사되어요.</span>
                </div>
                <ul>
                    <li>
                        <div className={listSt.ListTitle}>
                            인적사항
                        </div>
                        <div className={clsx(listSt.listContent, listSt.manyRow)}>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    법인명
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.businessRegistration.companyName)}>{vm.businessRegistration.companyName}</span>
                                </div>
                            </div>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    대표자
                                </div>
                                <div className={listSt.innerContent}>
                                    {vm.businessRegistration.businessOwners.map((businessOwner, index) => (
                                        <div className={listSt.row} key={index}>
                                            <span style={{marginRight:"12px"}}>{index+1}. {businessOwner.position}</span>
                                            <span style={{marginRight:"6px"}} className={listSt.copyText} onClick={() => copyText(businessOwner.name)}>{businessOwner.name}</span>
                                            <span className={listSt.copyText} onClick={() => copyText(businessOwner.uniqueInfo)}>{businessOwner.uniqueInfo}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    본점주소
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.businessRegistration.headOfficeAddress)}>{vm.businessRegistration.headOfficeAddress}</span>
                                </div>
                            </div>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    고객 연락처
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.phoneNumber)}>{vm.phoneNumber}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.ListTitle}>
                            법인현황
                        </div>
                        <div className={clsx(listSt.listContent, listSt.manyRow)}>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    법인등록번호
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.businessRegistration.cssn)}>{vm.businessRegistration.cssn}</span>
                                </div>
                            </div>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    자본금
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(addCommas(vm.businessRegistration.capital))}>{addCommas(vm.businessRegistration.capital)}</span>
                                    <span style={{marginLeft:"4px"}}>원</span>
                                </div>
                            </div>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    사업연도
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.businessRegistration.businessYear)}>{vm.businessRegistration.businessYear}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.ListTitle}>
                            사업장현황
                        </div>
                        <div className={clsx(listSt.listContent, listSt.manyRow)}>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    등기부상 설립일자
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.businessRegistration.establishedAt)}>{vm.businessRegistration.establishedAt}</span>
                                </div>
                            </div>
                            <div className={listSt.listRow} style={{marginTop:'14px'}}>
                                <div className={listSt.innerTitle}>
                                    고객이 입력한 사업개시일
                                </div>
                                <div className={listSt.innerContent}>
                                    <span className={listSt.copyText} onClick={() => copyText(vm.businessRegistration.dateOfBusinessCommencementByCustomer)}>{vm.businessRegistration.dateOfBusinessCommencementByCustomer}</span>
                                </div>
                            </div>
                            <div className={listSt.listRow} style={{marginTop:'14px'}}>
                                <div className={listSt.innerTitle}>
                                    사업자등록 업종
                                </div>
                                <div className={listSt.innerContent}>
                                    {vm.businessRegistration.typeOfBusinesses.map((typeOfBusiness, index) => (
                                        <React.Fragment key={index}>
                                            <div className={listSt.row}>
                                                <span>{index+1}.</span>
                                                <span style={{marginLeft:'3px'}}className={listSt.copyText} onClick={() => copyText(vm.deletePurposeNumber(typeOfBusiness.businessPurpose))}>{vm.deletePurposeNumber(typeOfBusiness.businessPurpose)}</span>
                                            </div>
                                            <div className={listSt.row} style={{marginTop:'8px'}}>
                                                <div style={{marginBottom:"6px"}}>고객이 입력한 설명 : {typeOfBusiness.description}</div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={listSt.ListTitle}>
                            기타
                        </div>
                        <div className={clsx(listSt.listContent, listSt.manyRow)}>
                            <div className={listSt.listRow}>
                                <div className={listSt.innerTitle}>
                                    홈텍스 수임동의
                                </div>
                                <div className={listSt.innerContent}>
                                    {vm.hometaxAcceptanceConsentState()}
                                </div>
                            </div>
                            <div className={listSt.listRow} style={{marginTop:"24px"}}>
                                <div className={listSt.innerTitle}>
                                    {vm.josa(vm.text)} 대표 관계 
                                </div>
                                <div className={listSt.innerContent}>
                                    {vm.businessRegistration.representativeFamilyRelationships.map((representativeFamilyRelationship, index) => (
                                        <div className={listSt.row} key={index}>
                                            <span style={{marginRight:"12px"}}>{index+1}. {representativeFamilyRelationship.name}</span>
                                            <span className={listSt.copyText} onClick={() => copyText(representativeFamilyRelationship.relationship)}>{representativeFamilyRelationship.relationship}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={listSt.listRow} style={{marginTop:"24px"}}>
                                <div className={listSt.innerTitle}>
                                    파일 다운로드
                                </div>        
                                <div className={listSt.innerContent}>
                                    <div className={listSt.row}>
                                        <FileBtn className={st.fileBtn} style={{width:"200px"}} onClick={() => vm.fileDownload(`/business/${businessId}/businessRegistration/downloadFilesAll`)}>한번에 다운로드 받기</FileBtn>
                                    </div>
                                    <div className={listSt.row}>
                                        <span>1. 등기부</span>
                                        <button type='button' className={listSt.fileDownBtn} onClick={() => vm.fileDownload(vm.businessRegistration.fileUrl.copyFileUrl)}/>
                                    </div>
                                    <div className={listSt.row}>
                                        <span>2. 정관</span>
                                        <button type='button' className={listSt.fileDownBtn} onClick={() => vm.fileDownload(vm.businessRegistration.fileUrl.aoaFileUrl)}/>
                                    </div>
                                    <div className={listSt.row}>
                                        <span>3. 설립당시 {vm.text}명부</span>
                                        <button type='button' className={listSt.fileDownBtn} onClick={() => vm.fileDownload(vm.businessRegistration.fileUrl.stockholderListFileUrl)}/>
                                        <div className={listSt.ex} style={{marginTop:"8px"}}>설립 후 상당한 기간이 지난 경우라면, {vm.text}명부가 바뀌었는지 확인필요</div>
                                    </div>
                                    <div className={listSt.row}>
                                        <span>4. 임대차계약서</span>
                                        <button type='button' className={listSt.fileDownBtn} onClick={() => vm.fileDownload(vm.businessRegistration.fileUrl.corporateLeaseAgreementFileUrl)}/>
                                    </div>
                                    <div className={listSt.row}>
                                        <span>5. 인허가증</span>
                                        <button type='button' className={listSt.fileDownBtn} onClick={() => vm.fileDownload(vm.businessRegistration.fileUrl.permitFileUrl)}/>
                                    </div>
                                    <div className={listSt.row}>
                                        <span>6. 대표님 신분증 사본</span>
                                        <button type='button' className={listSt.fileDownBtn} onClick={() => vm.fileDownload(vm.businessRegistration.fileUrl.representativeIDFileUrl)}/>
                                    </div>
                                </div>
                            </div>
                            <div className={listSt.listRow} style={{marginTop:"24px"}}>
                                <div className={listSt.innerTitle}>
                                    인감증명서 다운로드
                                </div>    
                                <div className={listSt.innerContent}>
                                    <a href={vm.businessRegistration.certificateOfRegisteredSealUrl} target="_blank" className={listSt.outLink}><span>인감증명서 다운로드 바로가기</span></a>
                                    <div className={listSt.exText} style={{marginTop:"8px"}}>(구글드라이브로 이동)</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    ));
}
export default BusinessRegistration;