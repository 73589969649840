import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './AlimtalkSendModalVm';
import st from './AlimtalkSendModal.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

function AlimtalkSendModal(props) {
    useEffect(() => {
        vm.load(props.businessId);
    }, [])
    return useObserver(() => (
        vm.alimtalkSendData?
            <div className={st.modal}>
                {props.businessId && 
                    <>
                        <div className={st.companyName}>{vm.alimtalkSendData.businessName}</div>
                        <div className={st.listTitle}>알림톡 받으실 분</div>
                        <ul className={st.targetList}>
                            {vm.alimtalkSendData.targets.map((target, index) => (
                                <li key={index}>
                                    <CheckBox checked={vm.alimtalkSendData.selectedIds.includes(target.id)} onChange={(e) => vm.selectedIds(e, target.id)}>{target.name}</CheckBox>
                                </li>
                            ))}
                        </ul>
                    </>
                }
                <MessageHint text="알림톡 메세지보기">
                    <img src='/images/talkModalPreview.svg'/>
                </MessageHint>
                <div className={st.contentTitle}>내용입력</div>
                <textarea placeholder='예시) 내용 확인 부탁드릴게요! 문의사항이 있으신 경우 아래 상담받기 버튼을 눌러 편하게 문의주세요!' className={st.textarea} value={vm.alimtalkSendData.content} onChange={vm.changeContent}></textarea>
                <div className={st.btnArea}>
                    <button type='button' disabled={!vm.canSendTalk(props.businessId)} className={st.sendBtn} onClick={(e) => vm.send(props.businessId, props.businessIds)}><span>발송하기</span></button>
                </div>
            </div>
        :<></>
    ));
}
export default AlimtalkSendModal;