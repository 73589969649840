import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import vm from './ModalEditVm';
import st from './ModalEdit.module.scss';
import queryString from 'query-string';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';
import clsx from 'clsx';

function ModalEdit(props) {
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;

    const [opened, setOpened] = useState(false);
    const [obValue, setObValue] = useState(props.value);

    const datePickerChange = function(e) {
        let year = ""; let month = ""; let day = "";
        if(e) {
            year = e.getFullYear();
            month = ('0' + (e.getMonth() + 1)).slice(-2);
            day = ('0' + e.getDate()).slice(-2);
            setObValue(year+"-"+month+"-"+day);
        }
    }

    const priceChange = function(e) {
        setObValue(onlyNumber(removeCommas(e.target.value)));
    }
    return useObserver(() => (
        <div className={st.modalEdit}>
            <button type="button" className={st.editBtn} onClick={() => setOpened(!opened)}/>
            {opened && 
                <form className={st.modal} onSubmit={(e) => vm.submit(e, props.type, businessId, props.inputType)}>
                    <button type='button' className={st.close} onClick={() => setOpened(!opened)}/> 
                    {props.inputType === "text" && 
                        <input type="text" name="value" required className={st.inputText} defaultValue={props.value}/>
                    }
                    {(props.inputType === "select" || props.inputType === "selectLong") && 
                        <select defaultValue={props.value} className={clsx(st.select, props.inputType === "selectLong" ? st.selectLong : null)} name="value" required>
                            <option value=""> - </option>
                            {props.options.map((option, index) => (
                                <option key={index} value={option.value}>{option.text}</option>
                            ))}
                        </select>
                    }
                    {props.inputType === "date" && 
                        <DatePicker className={st.datepicker} selected={obValue} onChange={(e) => datePickerChange(e)} required name="value"/>
                    }
                    {props.inputType === "price" && 
                        <div className={st.price}>
                            <input type="text" name="value" required className={st.inputText} value={addCommas(obValue)} onChange={(e) => priceChange(e)}/>
                            <span className={st.won}>원</span>
                        </div>
                    }
                    <button type='submit' className={st.submit}>저장</button>
                </form>
            }
        </div>
    ));
}
export default ModalEdit;