import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import AlimtalkCenterModel from '@model/AlimtalkCenterModel';

class AlimtalkCenterService {
    async load(keyword) {
        const res = await get(`/alimtalk/center?keyword=${keyword}`);
        AlimtalkCenterModel.setAlimtalkCenter(res.data);
    }
    async saveAlimtalkCenterList(param, businessId) {
        const res = await post(`/alimtalk/center/${businessId}`, param);
    }
}
export default new AlimtalkCenterService();