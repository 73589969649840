import { runInAction, observable, toJS } from "mobx";
import JoinService from '@service/JoinService.js';
import portalModel from '@model/portalModel';
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class JoinVm {
    constructor() {
        this.state = observable({
            action: false,
            email : "",
            name : "",
            phoneNumber : "",
            password : "",
            passwordCheck : "",
            terms : []
        })
    }
    changeEmail(e) {
        this.state.email = regExp.deleteSpace(e.target.value);
    }
    changePassword(e) {
        this.state.password = regExp.deleteSpace(e.target.value);
    }
    changePasswordCheck(e) {
        this.state.passwordCheck = regExp.deleteSpace(e.target.value);
    }
    changeName(e) {
        this.state.name = e.target.value;
    }
    async loadTerms() {
        const terms = await JoinService.loadTerms();
        terms.terms.forEach((term) => {
            term.checked = false
        });
        this.state.terms = terms.terms;
    }
    agreeAll(e) {
        this.state.terms.forEach((term) => {
            term.checked = e.target.checked
        });
    }
    get isAgreeAll() {
        if(this.state.terms.length === 0) {
            return false;
        }
        let checkedAll = true;
        this.state.terms.forEach((term) => {
            if(term.checked === false) {
                checkedAll = false;
            }            
        })
        return checkedAll;
    }
    get isSavePossible() {
        let agree = true;
        if(this.state.terms.length === 0) {
            return false;
        }
        this.state.terms.forEach((term) => {
            if(term.checked === false && term.requiredYn) {
                agree = false;
            }            
        })
        return agree;
    }
    agreeCheck(e, index) {
        this.state.terms[index].checked = e.target.checked;
    }
    openAgreeModal(title, content) {
        runInAction(() => {
            portalModel.title = title;
            portalModel.content = <div>{content}</div>;
        })
    }
    async submit(e) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            param.agreeTermIds = [];
            this.state.terms.forEach((term) => {
                if(term.checked) {
                    param.agreeTermIds.push(term.id)
                }
            })
            await JoinService.join(param);
        }
    }
}
export default new JoinVm();