import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import vm from './PreliminaryReportVm';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import st from '../Process.module.scss';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import queryString from 'query-string';
import DragFileUpload from '@commonComponents/DragFileUpload/DragFileUpload';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import { addCommas } from '@common/module/replaceNumber';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import Loading from '@standby/common-ui/components/atoms/Loading';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

function PreliminaryReport() {
    let location = useLocation();
    const businessId = queryString.parse(location.search).businessId;
    
    return useObserver(() => (
        <>
            {vm.state.loadingYn && <Loading />}
            <li>
                <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{width:"190px"}}>자료제출 안내</div>
                <div className={listSt.listContent}>
                    <ul className={listSt.flexList}>
                        <li className={listSt.hasBtn} style={{paddingTop:"55px"}}>
                            <div className={listSt.ex} style={{position:"absolute", top:"0", left:"0"}}>처음부터 예정신고 진행으로 선택되어 있다면, 자동으로 알림톡이 발송됩니다.<br/>타임라인을 확인하고, 자동알림톡이 발송되지 않은 경우에만 아래 버튼을 눌러주세요.</div>
                            <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                            <div className={listSt.rowContent}>
                                <ul className={st.checkList} style={{marginTop:'2px'}}>
                                    {vm.scheduledReportProgress.dataSubmissionAlimTalkToList.map((person, index) => (
                                        <li key={index}>
                                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.dataSubmissionAlimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div>
                                <button style={{width:"356px"}} type='button' disabled={!vm.dataSubmissionAlimTalkToActive} className={listSt.sendBtn} onClick={() => vm.dataSubmissionAlimTalk(businessId)}><span>저장 + 자료제출 안내 알림톡 발송</span></button>
                                <MessageHint text="알림톡 메세지보기">
                                    <img src='/images/talkPreview_자료제출안내.svg'/>
                                </MessageHint>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{width:"190px"}}>자료제출 재촉</div>
                <div className={listSt.listContent}>
                    <ul className={listSt.flexList}>
                        <li className={listSt.hasBtn}>
                            <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                            <div className={listSt.rowContent}>
                                <ul className={st.checkList} style={{marginTop:'2px'}}>
                                    {vm.scheduledReportProgress.promptForDataSubmissionAlimTalkToList.map((person, index) => (
                                        <li key={index}>
                                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.promptForDataSubmissionAlimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            
                        </li>
                        <li>
                            <div>
                                <button style={{width:"356px"}} type='button' disabled={!vm.promptForDataSubmissionAlimTalkToActive} className={listSt.sendBtn} onClick={() => vm.promptForDataSubmissionAlimTalk(businessId)}><span>저장 + 자료제출 재촉 알림톡 발송</span></button>
                                <MessageHint text="알림톡 메세지보기">
                                    <img src='/images/talkPreview_자료제출재촉.svg'/>
                                </MessageHint>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{width:"190px"}}>부가세 신고준비 시작</div>
                <div className={listSt.listContent}>
                    <ul className={listSt.flexList}>
                        <li className={listSt.hasBtn}>
                            <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                            <div className={listSt.rowContent}>
                                <ul className={st.checkList} style={{marginTop:'2px'}}>
                                    {vm.scheduledReportProgress.readyToFileVatAlimTalkToList.map((person, index) => (
                                        <li key={index}>
                                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.readyToFileVatAlimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div>
                                <button style={{width:"356px"}} type='button' disabled={!vm.readyToFileVatAlimTalkActive} className={listSt.sendBtn} onClick={() => vm.readyToFileVatAlimTalk(businessId)}><span>저장 + 자료수집 완료 & 부가세 준비시작 알림톡 발송</span></button>
                                <MessageHint text="알림톡 메세지보기">
                                    <img src='/images/talkPreview_자료수집완료.svg'/>
                                </MessageHint>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div className={listSt.ListTitle} style={{width:"190px"}}>부가세 신고완료</div>
                <div className={listSt.listContent}>
                    <ul className={listSt.flexList}>
                        <li>
                            <div className={listSt.rowTitle}>서류 업로드</div>
                            <div className={listSt.rowContent}>
                                <div className={st.fileList}>
                                    <div className={st.fileWrap}>
                                        <div className={st.title}>납부서 <span>{vm.total > 0 ? "(필수)" : "(납부액 발생시 업로드)"}</span></div>
                                        <DragFileUpload uploadFile={vm.vatReturnCompleted.statementOfPayment} changeUploadFile={vm.changeStatementOfPayment} uploadBtnText={<><strong>부가세 납부서</strong> 업로드</>}/>
                                    </div>
                                    <div className={st.fileWrap}>
                                        <div className={st.title}>부가세 신고서 <span>(필수)</span></div>
                                        <DragFileUpload uploadFile={vm.vatReturnCompleted.statementOfVatReturn} changeUploadFile={vm.changeStatementOfVatReturn} uploadBtnText={<><strong>부가세 신고서</strong> 업로드</>}/>
                                    </div>
                                </div>
                                <div className={st.fileList}>
                                    <div className={st.fileWrap}>
                                        <div className={st.title}>매입매출장 <span>(선택)</span></div>
                                        <DragFileUpload multiple uploadFile={vm.vatReturnCompleted.purchaseAndSalesLedger} changeUploadFile={vm.changePurchaseAndSalesLedger} uploadBtnText="업로드"/>
                                    </div>
                                    <div className={st.fileWrap}>
                                        <div className={st.title}>기타서류 <span>(선택)</span></div>
                                        <DragFileUpload multiple uploadFile={vm.vatReturnCompleted.etcFile} changeUploadFile={vm.changeEtcFile} uploadBtnText="업로드"/>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={listSt.rowTitle} style={{position:'relative', top:'8px'}}>부가세</div>
                            <div className={clsx(listSt.rowContent, st.inputList)}>
                                <div className={st.row}>
                                    <div className={st.title}>매출 부가세<span className={st.requiredMark}>*</span></div>
                                    <div className={st.inputs}>
                                        <InputText name="" value={addCommas(vm.vatReturnCompleted.vat.salesTax)} onChange={vm.salesTax} style={{textAlign:"right"}}/>
                                        <span className={st.inputSubText}>원</span>
                                    </div>
                                </div>
                                <div className={st.row}>
                                    <div className={clsx(st.title, st.minus)}>매입 부가세<span className={st.requiredMark}>*</span></div>
                                    <div className={st.inputs}>
                                        <InputText name="" value={addCommas(vm.vatReturnCompleted.vat.purchaseTax)} onChange={vm.purchaseTax} style={{textAlign:"right"}}/>
                                        <span className={st.inputSubText}>원</span>
                                    </div>
                                </div>
                                <div className={st.row}>
                                    <div className={clsx(st.title, st.plus)}>추가 납부세액</div>
                                    <div className={st.inputs}>
                                        <InputText name="" value={addCommas(vm.vatReturnCompleted.vat.additionalTaxAmountPaid)} onChange={vm.additionalTaxAmountPaid} style={{textAlign:"right"}}/>
                                        <span className={st.inputSubText}>원</span>
                                    </div>
                                </div>
                                <div className={st.row}>
                                    <div className={clsx(st.title, st.equal)}>납부세액</div>
                                    <div className={st.inputs}>
                                        <div style={{textAlign:"right"}} className={st.inputSizeView}>{addCommas(vm.total)}</div>
                                        <span className={st.inputSubText}>원</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className={listSt.rowTitle} style={{top:"7px"}}>부가세 납부기한<span className={st.requiredMark}>*</span></div>
                            <div className={listSt.rowContent}>
                                <DatePicker className={st.datePicker} name="" selected={vm.vatReturnCompleted.vat.paymentPeriod} onChange={vm.paymentPeriod}/>
                            </div>
                        </li>
                        <li>
                            <div className={listSt.rowTitle} style={{top:"-4px"}}>조기환급 대상</div>
                            <div className={listSt.rowContent}>
                                <CheckBox checked={vm.vatReturnCompleted.vat.earlyRefundYn} onChange={(e) => vm.earlyRefundYn(e)}>투자, 영세율 등 조기환급 대상자 여부</CheckBox>
                            </div>
                        </li>
                        <li className={listSt.hasBtn}>
                            <div className={listSt.rowTitle}>알림톡 받으실 분<span className={st.requiredMark}>*</span></div>
                            <div className={listSt.rowContent}>
                                <ul className={st.checkList} style={{marginTop:'2px'}}>
                                    {vm.vatReturnCompleted.alimTalkToList.map((person, index) => (
                                        <li key={index}>
                                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.alimTalkToList(e, index)}>{person.position ? `${person.position} : ` : ''} {person.name}님</CheckBox>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <li className={listSt.hasBtn}>
                            <div>
                                <button style={{width:"576px"}} type='button' disabled={!vm.activeVat} className={listSt.sendBtn} onClick={() => vm.sendVat(businessId)}><span>저장 + 부가세 신고완료 알림톡 발송</span></button>
                                <MessageHint text="알림톡 메세지보기">
                                    <img src='/images/talkPreview_부가세신고완료.svg'/>
                                </MessageHint>
                            </div>
                            <div className={listSt.saveFixBtn} style={{bottom:"0"}}>
                                <div className={listSt.btn}><button type="button" disabled={!vm.activeVat} onClick={() => vm.saveVat(businessId)}>수정사항 저장</button></div>
                                <div className={clsx(listSt.ex, !vm.activeVat ? listSt.disabled : null)}>저장시 고객화면 반영</div>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </>
    ));
}
export default PreliminaryReport;