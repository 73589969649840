import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import HeaderVm from './HeaderVm';
import st from './Header.module.scss';
import { Link, Route, withRouter, useLocation } from "react-router-dom";
import clsx from 'clsx';
import DialogBtn from '@standby/common-ui/components/atoms/Button/DialogBtn/DialogBtn';

import ScrollArea from 'react-custom-scrollbars-2';

function Header() {
    let location = useLocation();
    return useObserver(() => (
        <>
            <header id={st.header}>
                <div className={st.content}>
                    <Link className={st.logo} to="/business/list">
                        <div className={st.logoIcon}>
                            <img src='/images/companyLogo.svg'/>
                        </div>
                        <span>진평회계법인</span>
                    </Link>
                <div className={st.menuCover}>
                        <ul className={st.mainMenu}>
                            <li className={location.pathname.split("/")[1] === "myWork" ? st.active : null}><span>나의 할일</span></li>
                            <li className={location.pathname.split("/")[1] === "BusinessRegistration" ? st.active : null}><span>사업자등록</span></li>
                            <li className={location.pathname.split("/")[1] === "business"  || location.pathname === "/" ||  location.pathname.split("/")[1] === "delegationRegistration"? st.active : null}><span>거래처</span></li>
                            <li><span>노무관리</span></li>
                            <li className={location.pathname.split("/")[1] === "valueAddedTax" ? st.active : null}><span>세금</span></li>
                            <li><span>통장/카드</span></li>
                            <li><span>알림톡센터</span></li>
                            <li><span>권한관리</span></li>
                        </ul>
                        <div className={st.subMenuBackground}>
                            <ul className={st.subMenu}>
                                <li>
                                    <ul>
                                        {/* <li className={location.pathname === "/myWork/Home" ? st.active : null}><Link to="/myWork/Home">나의 할일</Link></li> */}
                                        <li><span className={st.yet}>나의 할일</span></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={location.pathname === "/BusinessRegistration/Management" ? st.active : null}><Link to="/BusinessRegistration/Management">사업자등록 연결</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={location.pathname === "/business/list"  || location.pathname === "/" ? st.active : null}><Link to="/business/list">거래처 목록</Link></li>
                                        <li className={location.pathname === "/delegationRegistration/list" ? st.active : null}><Link to="/delegationRegistration/list?type=전체">스탠바이 이용등록</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li><span className={st.yet}>급여/원천세</span></li>
                                        <li><span className={st.yet}>4대보험</span></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={location.pathname === "/valueAddedTax/list" ? st.active : null}><Link to="/valueAddedTax/list">부가세</Link></li>
                                        <li className={location.pathname === "/corporateTax/list" ? st.active : null}><Link to="/corporateTax/list">법인세</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li><span className={st.yet}>통장 불러오기</span></li>
                                        <li><span className={st.yet}>카드 불러오기</span></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={location.pathname === "/alimtalkCenter/list" ? st.active : null}><Link to="/alimtalkCenter/list">자동발송 일정관리</Link></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul>
                                        <li className={location.pathname === "/staff/list" ? st.active : null}><Link to="/staff/list">직원목록</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={st.menuBackgorund}></div>
                    <div className={st.side}>
                        <button type="button" className={clsx(st.buttonIcon, st.notify,  HeaderVm.newNotifyYn ? st.new : null)} onClick={() => HeaderVm.openNotification()}>알림</button>
                        <button type="button" className={clsx(st.buttonIcon, st.mypage)} onClick={() => HeaderVm.openUserSetting()}>{HeaderVm.userName}님</button>
                        <button type="button" className={st.logout} onClick={HeaderVm.logout}/>
                    </div>
                </div>
            </header>
            <div className={clsx(st.notifyWrap, HeaderVm.state.openNotify ? st.open : null)}>
                <div className={st.notifyRelative}>
                    <div className={st.background} onClick={() => HeaderVm.openNotification()}></div>
                    <div className={st.notifyBox}>
                        <div className={st.title}>알림</div>
                        {HeaderVm.notifications.length === 0 && 
                            <div className={st.empty}>새로운 알림이 없습니다.</div>
                        }
                        {HeaderVm.notifications.length !== 0 && 
                            <div className={st.list} style={{height:"calc(100% - 57px)"}}>
                                <ScrollArea className={st.scrollArea} renderThumbVertical={props => <div {...props} style={{width:"3px", backgroundColor:'#ADAFB1'}}/>}>
                                    <div className={st.inside}>
                                        {HeaderVm.notifications.filter(notification => notification.notificationType === "INVITE").map((notification, index) => (
                                            <div className={st.confirm} key={index}>
                                                <div className={st.detail}>{notification.content}</div>
                                                <div className={st.time}>{notification.createdAt}</div>
                                                <div className={st.btnArea}>
                                                    <DialogBtn color="confirm" onClick={() => HeaderVm.InviteTypeAccept(notification.callbackUrl, true, notification.notificationId)}>수락</DialogBtn>
                                                    <DialogBtn onClick={() => HeaderVm.InviteTypeAccept(notification.callbackUrl, false, notification.notificationId)}>거절</DialogBtn>
                                                </div>
                                            </div>
                                        ))}
                                        {HeaderVm.notifications.filter(notification => notification.notificationType === "GENERAL").length !== 0 &&
                                            <div className={st.readList}>
                                                {HeaderVm.notifications.filter(notification => notification.notificationType === "GENERAL").map((notification, index) => (
                                                    <div className={clsx(st.readType, notification.readAt ? st.read : null)} key={index}>
                                                        <div className={st.detail} dangerouslySetInnerHTML={{__html: notification.content}}/>
                                                        <div className={st.time}>{notification.createdAt}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </ScrollArea>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    ));
}
export default Header;