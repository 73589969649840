import { runInAction, observable, toJS } from "mobx";
import ChangeEmailService from '@service/ChangeEmailService';
import formDataToJson from '@common/module/submit';
import regExp from '@common/module/regExp';

class ChangeEmailVm {
    constructor() {
        this.state = observable({
            email : ""
        })
    }
    changeEmail(e) {
        this.state.email = regExp.deleteSpace(e.target.value);
    }
    async submit(e, token) {
        e.preventDefault();
        if(formDataToJson(e.target).error) {
            return
        } else {
            let param = formDataToJson(e.target).data;
            ChangeEmailService.changeEmail(param, token);
        }
    }
}
export default new ChangeEmailVm();