import { observable } from 'mobx';

const DelegationRegistrationListModel = observable({
    columns : [],
    tabs : [],
    setColumns(columns) {
        this.columns = columns || []
    },
    setTabs(tabs) {
        this.tabs = tabs.map((tab) => {
            return {
                name : tab.name || "",
                delegationRegistrationStatusType : tab.delegationRegistrationStatusType || null,
                count : tab.count || 0
            }
        })
    }
});

export default DelegationRegistrationListModel;