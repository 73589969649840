import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from "react-router-dom";
import { useEffect } from 'react';
import vm from './FindPasswordVm';
import st from './FindPassword.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function FindPassword() {
    useEffect(() => {
        vm.state.sendYn = false;
    }, [])
    return useObserver(() => (
        <div className={st.findPassword}>
            <form className={st.findPasswordForm} onSubmit={(e) => vm.submit(e)}>
                <div className={st.logo}>
                    <img src='/images/loginLogo.svg?1'/>
                </div>
                {vm.state.sendYn === false && 
                    <>
                        <div className={st.explain}>
                            <div className={st.title}>가입된 이메일을 입력해 주세요.</div>
                            <div className={st.content}>입력한 이메일로 비밀번호 재설정 링크를 보내드려요.</div>
                        </div>
                        <div className={st.inputRow}>
                            <div className={st.label}>
                                <label htmlFor="email">이메일</label>
                            </div>
                            <InputText id="email" fullSize value={vm.state.email} onChange={(e) => vm.changeEmail(e)} name="email"
                                errorText={<Error name="email" value={vm.state.email} errorCase={{
                                    required: '이메일 주소를 입력하세요.',
                                }}/>}
                            />
                        </div>
                        <div className={st.btnArea}>
                            <SquareBtn className={st.findBtn} type="submit">비밀번호 재설정하기</SquareBtn>
                        </div>
                    </>
                }
                {vm.state.sendYn === true && 
                    <>
                        <div className={st.explain} style={{marginBottom:"56px"}}>
                            <div className={st.title}>메일함을 확인해 주세요 :)</div>
                        </div>
                        <div className={st.inputRow}>
                            <div className={st.label}>
                                <label>발송한 이메일 주소</label>
                            </div>
                            <InputText fullSize value={vm.state.email} name="" disabled/>
                            <input type="hidden" name="email" value={vm.state.email}/>
                        </div>
                        <div className={st.explainEmailSend}>
                            혹시 이메일이 오지 않았다면, 스팸함을 확인해 주세요.
                        </div>
                        <div className={st.btnArea}>
                            <SquareBtn className={st.findBtn} type="submit" color="white"><span className={st.resend}>이메일 다시 보내기</span></SquareBtn>
                        </div>
                        <div className={st.back}>
                            <button type='button' onClick={() => {vm.state.sendYn = false}}>이전으로 돌아가기</button>
                        </div>
                    </>
                }
            </form>
        </div>
    ));
}
export default FindPassword;