import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import CorporateTaxModel from '@model/CorporateTaxModel';
import { _alert, _confirm } from "@model/dialogModel";
import formDataToJson, {objectToFormData} from '@common/module/submit';

class CorporateTaxService {
    async load(businessId, year) {
        const res = await get(`/business/${businessId}/corporateTax/${year}`); 
        CorporateTaxModel.setProcess(res.data);
    }
    async sendTalk(param, businessId, type) {
        let _param = param;

        const res = await post(`/business/${businessId}/corporateTax/${type}`, param);
        if (window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async taxInfoLoad(businessId, year, taxBillSendWayType, instalmentYn) {
        const res = await get(`/business/${businessId}/corporateTax/${year}/taxInfo?taxBillSendWayType=${taxBillSendWayType}&instalmentYn=${instalmentYn}`); 
        CorporateTaxModel.setTaxInfo(res.data.taxInfo);
    }
    async sendCompletedNotice(param, businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/corporateTax/completedNotice`, formData);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async saveCompletedNotice(param, businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/corporateTax/complete`, formData);
        await _alert('저장되었습니다.');
        window.location.reload();
    }
    async sendNationalTaxCompletedNotice(param, businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/corporateTax/nationalTax/completedNotice`, formData);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async saveaNationalTaxCompletedNotice(param, businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/corporateTax/nationalTax/complete`, formData);
        await _alert('저장되었습니다.');
        window.location.reload();
    }
    async sendLocalTaxCompletedNotice(param, businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/corporateTax/localTax/completedNotice`, formData);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('알림톡 발송을 시작합니다.', '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)');
        window.location.reload();
    }
    async saveLocalTaxCompletedNotice(param, businessId) {
        const formData = new FormData();
        objectToFormData(formData, param);
        const res = await post(`/business/${businessId}/corporateTax/localTax/complete`, formData);
        await _alert('저장되었습니다.');
        window.location.reload();
    }
}
export default new CorporateTaxService();