import { runInAction, observable, toJS } from "mobx";
import formDataToJson from '@common/module/submit';
import LoginService from '@service/LoginService';
import regExp from '@common/module/regExp';

class loginVm {
    constructor() {
        this.state = observable({
            email : "",
            password : "",
            autoLogin : false
        });
        this.loginApi = false;
    }
    changeEmail(e) {
        this.state.email = regExp.deleteSpace(e.target.value);
    }
    changePassword(e) {
        this.state.password = regExp.deleteSpace(e.target.value);
    }
    autoLogin(e) { 
        this.state.autoLogin = e.target.checked;
    }
    async submit(e) {
        e.preventDefault();
        if(this.loginApi === false) {
            this.loginApi = true;
            if(formDataToJson(e.target).error) {
                return
            } else {
                let param = formDataToJson(e.target).data;
                try {
                    await LoginService.login(param, this.state.autoLogin);
                    this.loginApi = false;
                }catch (err) {
                    this.loginApi = false;
                }
            }   
        }
    }
}
export default loginVm;