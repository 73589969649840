import { observable } from 'mobx';

const UserSettingModel = observable({
    userSetData : {
        email : "",
        name : "",
        marketingAcceptYn : false,
        marketingAcceptEmailYn : false,
        marketingYnChangedDate : null,
        marketingTCAgreementId : null,
        marketingTermContent : "",
    },
    setUserSetData(data) {
        this.userSetData = {
            email : data?.email || "",
            name : data?.name || "",
            marketingAcceptYn : data?.marketingAcceptYn || false,
            marketingAcceptEmailYn : data?.marketingAcceptEmailYn || false,
            marketingYnChangedDate : data?.marketingYnChangedDate || null,
            marketingTCAgreementId : data?.marketingTCAgreementId || null,
            marketingTermContent : data?.marketingTermContent || "",
            
        }
    }
});

export default UserSettingModel;