import formDataToJson from '@common/module/submit';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';

class ModalEditVm {
    async submit(e, type, businessId, inputType) {
        e.preventDefault();
        let value = formDataToJson(e.target).data.value;
        if(value === "true" || value === "false") {
            value = value === "true";
        }else if(inputType === "price") {
            value = removeCommas(value);
        }
        const param = {
            type, value
        }
        BusinessHomeService.editHome(param, businessId);
    }
}
export default new ModalEditVm();