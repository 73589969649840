import axios from 'axios';
import { pageRouter } from '@model/pageModel';
import loginService from '@service/LoginService'; 
import Cookies from 'js-cookie';
import { _alert, _confirm } from "@model/dialogModel";

const baseURL = process.env.REACT_APP_API_URL || 'empty';
const httpRequest = axios.create({
    baseURL: baseURL,
    headers: { 'Cache-Control': 'no-cache' }
});

httpRequest.interceptors.request.use(
    function(config) {
        if(localStorage.getItem('rememberMeToken')) {
            if(localStorage.getItem('accessToken')) {
                config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
            }
        }else{
            if(Cookies.get('accessToken')) {
                config.headers.Authorization = `Bearer ${Cookies.get('accessToken')}`;
            }
        }
        return config
    },
);
let loginAlert = false;
httpRequest.interceptors.response.use(
    function(response) {
        return response
    },
    async function(error) {
        if(error.request.responseType === 'blob') {
            error.response.data = JSON.parse(await error.response.data.text());
        }
        if(error?.response?.data?.message) {
            if(loginAlert === false) {
                await _alert(error?.response?.data?.message, error?.response?.data?.subMessage || '') 
            }
            if(loginAlert === false && error?.response?.status === 401) {
                loginAlert = true;
            }
        }
        
        if(error?.response?.status === 401) {
            if(error?.response?.data?.errCode === "WRONG_TOKEN") { //토큰없이 회원사용api요청
                loginService.logoutClient();
                window.location.replace(`/auth/login`);
                return;
            }
            if(error?.response?.data?.errCode === "EXPIRED_TOKEN") {//엑세스 토큰만료
                return await resetTokenAndReattemptRequest(error)
            }
        }

        if(error?.response?.data?.redirectUrl) {
            window.location.replace(error?.response?.data?.redirectUrl);
        }
        return Promise.reject(error);
    }
);


let isAlreadyFetchingAccessToken = false;
let subscribers = [];
async function resetTokenAndReattemptRequest(error) {
    try {
        const errorResponse = error.response;
        const retryOriginalRequest = new Promise((resolve, reject) => {
            addSubscriber(async (accessToken) => {
                try {
                    errorResponse.config.headers['Authorization'] ='Bearer ' + accessToken;
                    resolve(httpRequest(errorResponse.config));
                } catch (err) {
                    reject(err);
                }
            });
        });
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            const { data } = await postRefresh();
            if(localStorage.getItem('rememberMeToken')) {
                localStorage.setItem('rememberMeToken', data.rememberMeToken)
                localStorage.setItem('accessToken', data.accessToken)
            }else{
                Cookies.set('accessToken', data.accessToken);
                Cookies.set('refreshToken', data.refreshToken);
            }
            isAlreadyFetchingAccessToken = false;

            onAccessTokenFetched(data.accessToken);
        }

        return retryOriginalRequest; 
    }catch (error) {
        await _alert(error?.response?.data?.message, error?.response?.data?.subMessage || '');
        window.location.replace(`/auth/login`);
        loginService.logoutClient();
        return Promise.reject(error);
    }

    function addSubscriber(callback) {
        subscribers.push(callback);
    }
    
    function onAccessTokenFetched(accessToken) {
        subscribers.forEach((callback) => callback(accessToken));
        subscribers = [];
    }

    async function postRefresh() {
        if(localStorage.getItem('rememberMeToken')) {
            return await axios.post(`${baseURL}auth/reIssue`, {}, {
                headers : {
                    "Remember-Me-Authorization" : localStorage.getItem('rememberMeToken')
                }
            })
        }else{
            return await axios.post(`${baseURL}auth/reIssue`, {}, {
                headers : {
                    "Refresh-Authorization" : Cookies.get('refreshToken')
                }
            })
        }
    }
}

function get(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.get(url, {params : body, headers: {...option}}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function _delete(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.delete(url, body, {headers: {...option}}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function put(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.put(url, body, {headers: {...option}}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function post(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.post(url, body, {headers: {...option}}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function patch(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.patch(url, body, {headers: {...option}}).then((data) => {
            resolve(data);
        }).catch((error) => {
            reject(error);
        })
    })
}
function downloadFileGet(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.get(url, {params : body, headers: {...option}, responseType:"blob"}).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            const contentDisposition =  decodeURIComponent(res.headers['content-disposition']); // 파일 이름
            let fileName = 'unknown';
            if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve(res);
        }).catch((error) => {
            reject(error);
        })
    })
}
function downloadFilePost(url, body, option) {
    return new Promise((resolve, reject) => {
        httpRequest.post(url, {params : body, headers: {...option}, responseType:"blob"}).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            const contentDisposition =  decodeURIComponent(res.headers['content-disposition']); // 파일 이름
            let fileName = 'unknown';
            if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
            if (fileNameMatch)
                [ , fileName ] = fileNameMatch.split('=');
            }
            link.href = url;
            link.setAttribute('download', `${fileName}`);
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve(res);
        }).catch((error) => {
            reject(error);
        })
    })
}

export { get, post, _delete, put, patch, downloadFileGet, downloadFilePost };
