import { toJS, observable } from 'mobx';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';

class AvoidBookkeepingContractModalVm {
    constructor() {
        this.data = observable({
            reasonsForTerminatingContract : '',
            contractEndDate: ''
        });
    }
    reasonsForTerminatingContract(e) {
        this.data.reasonsForTerminatingContract = e.target.value;
    }
    contractEndDate(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        this.data.contractEndDate = date;
    }
    get saveActive() {
        let active = true;
        if(!this.data.reasonsForTerminatingContract) active = false;
        if(!this.data.contractEndDate) active = false;
        return active;
    }
    send(businessId) {       
        let param = {};
        param.reasonsForTerminatingContract = this.data.reasonsForTerminatingContract;
        param.contractEndDate = this.data.contractEndDate;
        BusinessHomeService.avoidanceBookkeepingContract(businessId, param);
    }
}
export default AvoidBookkeepingContractModalVm;