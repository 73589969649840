import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './StaffListVm';
import PageNav from '@commonComponents/PageNav/PageNav';
import st from './StaffList.module.scss';

function StaffList() {
    useEffect(() => {
        vm.load();
    }, [])
    return useObserver(() => (
        <>
            <PageNav nav={["권한관리", "직원목록"]}/>
            <div className={st.tableTitle}>재직중</div>
            <table className={st.staffList}>
                <colgroup>
                    <col width="224"/>
                    <col width="162"/>
                    <col width="267"/>
                    <col width="177"/>
                    <col/>
                </colgroup>
                <thead>
                    <tr>
                        <th>이름</th>
                        <th>직위</th>
                        <th>이메일</th>
                        <th>최고관리자여부</th>
                        <th>조직</th>
                    </tr>
                </thead>
                <tbody>
                    {vm.employees.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.title}</td>
                            <td>{item.email}</td>
                            <td>{item.role}</td>
                            <td>{item.teams.join(', ')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    ));
}
export default StaffList;