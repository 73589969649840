import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import LoginedVm from './LoginedVm';
import Header from '@commonComponents/Header/Header';
import st from './Logined.module.scss';
function Logined(props) {
    return useObserver(() => (
        <>
            <Header/>
            <div className={st.content}>
                {props.children}
            </div>
        </>
    ));
}
export default Logined;